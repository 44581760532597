<app-top-navigation (uploaded)="someMethod($event)"></app-top-navigation>

<div class="ui segment basic" style="height: 80vh; margin-top: 120px" *ngIf="users_list.length==0">
    <div class="ui active inverted dimmer">
        <div class="ui text loader">Loading</div>
    </div>
</div>

<div class="ui segment basic" style="margin-top: 80px" *ngIf="users_list.length>0">
<div class="columns">
        <div class="column">
            <div class="ui search fluid" id="user_search" style="width: 480px;float: right">
                <div class="ui left icon input fluid">
                    <input class="prompt" type="text" id="user_search_field" placeholder="Search Users ....." autofocus style="font-style: italic;font-weight: bold;color: black;">
                    <i class="fluid search icon"></i>
                    <button class="tiny ui orange button" (click)="get_all_users()">
                        Reset
                    </button>
                </div>
            </div>
        </div>
    </div>
  <table class="ui single line table large selectable">
        <thead>
        <tr>
            <th>Name and Surname</th>
            <th>Email</th>
            <th>Institution</th>
            <th>Date Created</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let u of currentItems; let i = index">
            <tr>
                <td style="font-weight: bold">
                    {{u.firstName}} {{u.lastName}}
                    <!--<i class="expand arrows alternate icon" (click)="open_profile_image(u,i)"></i>-->
                </td>
                <td>{{u.email}}</td>
                <td>
                    <h4 class="ui image header">
                        <!--<img src="../../../assets/images/{{u?.institution?.imageName}}" class="ui mini rounded image">-->
                        <div class="content">
                           {{u.institution.name | titlecase}}
                            <div class="sub header">{{u.institution.abbreviation | uppercase}}
                            </div>
                        </div>
                    </h4>
                </td>
                <td>{{u.createdAt | date:'y-MMMM-d'}}</td>
                <ng-container *ngFor="let r of u.roles; let i = index">
                    <td>{{r.name}}</td>
                    </ng-container>
                 <td class="collapsing">
                    <span *ngIf="u.status == 'PENDING'">
                        <a class="ui yellow empty circular label"></a>
                        Pending
                    </span>
                    <span *ngIf="u.status == 'REJECTED'">
                        <a class="ui red empty circular label" *ngIf="u.status == 'REJECTED'"></a>
                        Rejected
                    </span>
                    <span *ngIf="u.status == 'DECLINED'">
                        <a class="ui red empty circular label" *ngIf="u.status == 'DECLINED'"></a>
                        Declined
                    </span>
                    <span *ngIf="u.status == 'APPROVED'">
                        <a class="ui green empty circular label" *ngIf="u.status == 'APPROVED'"></a>
                        Approved
                    </span>
                    <span *ngIf="u.status == 'CONFIRMED'">
                        <a class="ui yellow empty circular label" *ngIf="u.status == 'CONFIRMED'"></a>
                        Confirmed
                    </span>
                </td>
                <td class="collapsing">
                    <div class="ui buttons compact" *ngIf="u.status == 'PENDING'">
                        <button class="ui button" (click)="open_profile_image(u, i)" disabled style='margin-right:5px'>Aprove/Reject</button>&nbsp;
                        <button class="ui primary button" (click)="changeUserRole(u.id,u.email)" disabled>Change Role</button>

                        <button class="ui button red"
                                [disabled]="u.deleted === 'PENDING'"
                                (click)="u.deleted === 'DELETED' ? buttonHardDeleteUser(u, i) : (u.deleted === 'DECLINED' ? buttonDeclineDeleteUser(u, i) : buttonSoftDeleteUser(u, i))"
                                style='margin-left:5px'>
                            {{ u.deleted === 'DELETED' ? 'Remove' : (u.deleted === 'PENDING' ? 'Confirming...' : (u.deleted === 'DECLINED' ? 'Pre-delete' : 'Pre-delete')) }}
                        </button>&nbsp;

                    </div>

                    <div class="ui buttons compact" *ngIf="u.status == 'REJECTED'">
                        <button class="ui button" (click)="open_profile_image(u, i)" style='margin-right:5px'>Approve/Reject</button>&nbsp;
                        <button class="ui primary button" (click)="changeUserRole(u.id,u.email)" disabled>Change Role</button>


                    </div>
                    <div class="ui buttons compact" *ngIf="u.status == 'DECLINED'">
                        <button class="ui button" (click)="open_profile_image(u, i)" disabled style='margin-right:5px'>Approve/Reject</button>&nbsp;
                        <button class="ui primary button" (click)="changeUserRole(u.id,u.email)" disabled>Change Role</button>

                    </div>
                    <div class="ui buttons compact" *ngIf="u.status == 'APPROVED'">
                        <button class="ui button" (click)="open_profile_image(u, i)" style='margin-right:5px'>Approve/Reject</button>
                        <button class="ui primary button" (click)="changeUserRole(u.id,u.email)">Change Role</button>

                        <button class="ui button red"
                                [disabled]="u.deleted === 'PENDING'"
                                (click)="u.deleted === 'DELETED' ? buttonHardDeleteUser(u, i) : (u.deleted === 'DECLINED' ? buttonDeclineDeleteUser(u, i) : buttonSoftDeleteUser(u, i))"
                                style='margin-left:5px'>
                            {{ u.deleted === 'DELETED' ? 'Remove' : (u.deleted === 'PENDING' ? 'Confirming...' : (u.deleted === 'DECLINED' ? 'Pre-delete' : 'Pre-delete')) }}
                        </button>&nbsp;
                    </div>

                    <div class="ui buttons compact" *ngIf="u.status == 'CONFIRMED'">
                        <button class="ui button" (click)="open_profile_image(u, i)" style='margin-right:5px'>Approve/Reject</button>&nbsp;
                        <button class="ui primary button" (click)="changeUserRole(u.id,u.email)" disabled>Change Role</button>
                        <button class="ui primary button" (click)="buttonSoftDeleteUser(u,i)" disabled style='margin-left:5px'>Pre-delete</button>&nbsp

                    </div>
                </td>
            </tr>
        </ng-container>
        </tbody>

        <tfoot>
        <tr>
            <th colspan="8">
                <div class="ui right floated pagination menu">

                    <a class="icon item" (click)="previousPage()" *ngIf="!(currentPage === 0)">
                        <i class="left chevron icon"></i>
                    </a>


                    <a class="icon item"  (click)="nextPage()" *ngIf="!(currentPage === pageCount - 1)">
                        <i class="right chevron icon"></i>
                    </a>
                </div>
            </th>
        </tr>

        </tfoot>
    </table>
</div>


<div class="ui modal large" id="deactivated_users_modal">
    <div class="header">Header</div>
    <div class="content" style="padding: 0">
        <table class="ui single line table large selectable">
            <thead>
            <tr>
                <th>Name and Surname</th>
                <th>Email</th>
                <th>Date Created</th>
                <th>State</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let u of deactivated_user_list; let i = index">
                <tr>
                    <td style="font-weight: bold">{{u.firstName}} {{u.lastName}}</td>
                    <td>{{u.email}}</td>
                    <td>{{u.createdAt}}</td>
                    <td class="collapsing">
                        <a class="ui yellow empty circular label" *ngIf="u.status == 'PENDING'"></a>
                        <a class="ui red empty circular label" *ngIf="u.status == 'REJECTED'"></a>
                        <a class="ui green empty circular label" *ngIf="u.status == 'APPROVED'"></a>
                    </td>
                    <td class="collapsing">
                        <button class="ui button compact" (click)="restore_user(u.email)">Restore</button>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
</div>


<div class="ui modal small" id="users_rejection_modal" >
    <a class="ui button compact" style="float: right" (click)="btnRejectionClose()">X</a>
    <div class="header">Rejecting: {{current_user?.firstName}} {{current_user?.lastName}}</div>
    <div class="content">

        <div class="ui grid">
            <div class="eight wide column">
                <h4>
                    <img src="../../../assets/images/account.png" class="ui small rounded image" >
                </h4>
            </div>
            <div class="eight wide column">
                <h4>
                    <img src="../../../assets/images/{{current_user?.institution?.imageName}}" class="ui small rounded image"><br/>

                        Username: {{current_user?.email}}<br/><br/>
                        Institution Name: {{current_user?.institution?.name | titlecase}} ({{current_user?.institution?.abbreviation | uppercase}})<br/><br/>
                        Motivation: {{current_user?.motivation}}

                </h4>
            </div>
        </div>


        <div class="ui divider"></div>

        <form class="ui form" [formGroup]="rejection_reason_form" (ngSubmit)="rejection_reason_form_submit()">

            <div class="field" [class.error]="this.rejection_reason_form.get('id')?.invalid ">
                <label>Reason</label>
                <select class="ui dropdown" formControlName="id" id="reasons">
                    <option value=""></option>
                    <ng-container *ngFor="let t of rejection_reason_list">
                        <option value="{{t.id}}">{{t.reason}}</option>
                    </ng-container>
                </select>
            </div>

            <button class="ui button compact orange" type="submit" [ngClass]="{'loading disabled': is_reject_loading}" >Submit</button>
        </form>

    </div>
</div>



<div class="ui modal small" id="users_view_modal" >
    <a class="ui button compact" style="float: right" (click)="btnUserViewClose()">X</a>
    <div class="header">{{current_user?.firstName}} {{current_user?.lastName}}</div>
    <div class="content">

        <div class="columns">
            <div class="column is-narrow">
                <!--<img *ngIf="current_user?.profileImage?.data" class="ui small rounded image" [src]="'data:image/png;base64,' + current_user?.profileImage?.data" >-->
                <img  src="../../../assets/images/account.png" class="ui small rounded image" alt="Girl in a jacket" >
            </div>
            <div class="column">
                <h4>
                    <img src="../../../assets/images/{{current_user?.institution?.imageName}}" class="ui mini rounded image">
                    <br/>
                        Username: {{current_user?.email}}<br/><br/>
                        Institution Name: {{current_user?.institution?.name| titlecase}} ({{current_user?.institution?.abbreviation | uppercase}})<br/><br/>
                        Motivation: {{current_user?.motivation}}

                </h4>
            </div>
        </div>




    </div>
    <div class="actions">
        <button class="ui red button compact"  (click)="open_rejection_modal(current_user,current_user_index)" >Decline</button>
        <button class="ui green button compact" style="float: left" (click)="set_user_status(1,current_user?.email, current_user_index, null)">Approve</button>

        <!--<button class="ui button red" (click)="delete_user(current_user, current_user_index)" >Delete</button>-->
    </div>
</div>


<!--Change User Roles-->
<div class="ui modal small" id="changeUserRoleModel" >
    <a class="ui button compact" style="float: right" (click)="btnClose()">X</a>
 <div class="header">Change Role for Use {{updateRoleRequest.username}}</div>
 <div class="content">
    <form class="ui form" #changeRoleForm="ngForm" (ngSubmit)="changeRoleForm.form.valid && onSubmit()" novalidate>
        <div *ngIf="errorMessage" class="ui negative message">
            <div class="header">
                {{ errorMessage }}
            </div>
        </div>
    <div class="two fields">
      <div class="field">
            <label for="username">Username:</label>
            <input type="text" id="username" value="{{ updateRoleRequest.username }}" name="username" disabled>
        </div><br/>

        <div class="field">
            <label>Role:</label>
            <select class="ui fluid dropdown" [(ngModel)]="updateRoleRequest.roleId" name="roleId" #roleId="ngModel" required>
                <ng-container *ngFor="let role of roles">
                    <option value="{{role.id}}">{{role.name}}</option>
                </ng-container>
            </select>
            <label *ngIf="(changeRoleForm.submitted) && roleId.errors?.['required']" style="color: red">
                Please select the required role
            </label>
        </div>
        </div><br/>
        <button class="ui orange button" type="submit">Update</button>
    </form>
    </div>
    </div>



