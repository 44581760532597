import {User} from "./User";
import {Institution} from "./Institution";

export enum StorageRequestStatus {
    PENDING='PENDING',
    APPROVED='APPROVED',
    REJECTED='REJECTED',
    IMPLEMENTED='IMPLEMENTED'
}

export enum storageTerm {
    LONG='LONG',
    SHORT='SHORT',
    BOTH='BOTH'
}

export enum StorageExpectedMetric {
    GB='GB',
    TB='TB',
}

export enum StorageAmountMetric {
    GB='GB',
    TB='TB',
}

export enum StorageIP {
    YES='YES',
    NO='NO',
}

export enum StoragePeriod {
    MONTHS='MONTHS',
    YEARS='YEARS',
}

export class StorageRequest {

    id!: number
    expectedStorageSize!: number
    expectedStorageSizeMetric!: StorageExpectedMetric
    storageSize!: number
    storageSizeMetric!: StorageAmountMetric
    projectDescription!: string
    storageRequestMotivation!: string
    storageRequestMotivationDDT!: string
    storageDataDescription!: string
    storageTerm!: storageTerm
    estimatedStoragePeriod!: number
    estimatedStoragePeriodMetric!: StoragePeriod
    storageDataRetirement!: string
    storageDataPreservation!: string
    storageIntellectualProperty!: StorageIP
    storageIntellectualPropertyOwner!: string
    storageDataSpectrum!: string
    ddtStoragePeriod!: number
    ltaStoragePeriod!: number
    storageRequestStatus!: StorageRequestStatus
    storagePolicies!: boolean
    storagePrivacyPolicies!: boolean
    pstorageTermsCondition!: boolean
    primaryCustodianPhoneNumber!: number
    primaryCustodianDepartment!: string
    secondaryCustodianFirstName!: string
    secondaryCustodianLastName!: string
    secondaryCustodianEmail!: string
    secondaryCustodianPhoneNumber!: number
    secondaryCustodianDepartment!: string
    userResponse!: User;
    institution!: Institution;
}