<div class="ui grid">
    <div class="ten wide column"
         style="background-image:url('../../assets/images/login_3.jpg'); background-position: center center; background-size: cover;">
        <div class="ui segment basic" style="margin-top: 0vh; padding: 0 0px 0 50px; height: auto;" id="logo">
            <img src="../../../assets/images/DIRISA_logo_RGB.png" alt="DIRISA logo" height="80" style="margin: 0">
        </div>

    </div>
    <div class="six wide column is-narrow" style="background-color: rgb(0,0,0,0.02); min-height: 100%">


        <div class="" style=" margin-top: 45px">
            <h4 class="ui left aligned header grey" style="margin: 0; padding: 0 30px 0 50px; font-size: medium;">
                Register a New Account Below <strong>or</strong>
                <a class="cursor-link-pointer" (click)="go_to_login()"> log In</a>
            </h4><br />
        </div>
        <div style="min-width: 550px; padding: 0 60px 0 30px">
            <form class="ui form" [formGroup]="register_form" (ngSubmit)="register_form_submit()">

                <div class="fields">
                    <div class="eight wide field"
                         [class.error]="register_form.get('first_name')?.invalid && formCheck">
                        <label>First Name</label>
                        <input type="text" formControlName="first_name" autofocus placeholder="Enter your First Name">
                        <label *ngIf="register_form.get('first_name')?.invalid && formCheck" style="color: red">
                            Please enter a valid First Name, e.g., John
                        </label>
                    </div>
                    <div class="eight wide field"
                         [class.error]="register_form.get('last_name')?.invalid && formCheck">
                        <label>Last Name / Surname</label>
                        <input type="text" formControlName="last_name" placeholder="Enter your Last Name">
                        <label *ngIf="register_form.get('last_name')?.invalid && formCheck" style="color: red">
                            Please enter a valid Last Name / Surname, e.g., Smith
                        </label>
                    </div>
                </div>

                <div class="fields">
                    <div class="eight wide field"
                         [class.error]="register_form.get('username')?.invalid && formCheck">
                        <label>Email</label>
                        <input type="email" formControlName="username" placeholder="Enter your email address">
                        <label *ngIf="register_form.get('username')?.invalid && formCheck" style="color: red">
                            Please enter a valid email address, e.g., johnsmith@example.com
                        </label>
                    </div>
                    <div class="eight wide field"
                         [class.error]="register_form.get('institution')?.invalid && formCheck">
                        <label>Institution</label>
                        <select class="ui selection dropdown" formControlName="institution" id="institution">
                            <option value=""></option>
                            <ng-container *ngFor="let t of institutionList">
                                <option [value]="t.id">{{t.name}}</option>
                            </ng-container>
                        </select>
                        <label *ngIf="register_form.get('institution')?.invalid && formCheck" style="color: red">
                            Please select an institution
                        </label>
                    </div>
                </div>

                <div class="field"
                     [class.error]="(register_form.get('motivation')?.invalid && formCheck) || (leadingAndTrailingSpaces && formCheck)">
                    <label>Motivation</label>
                    <textarea rows="2"
                              formControlName="motivation"
                              maxlength="300"
                              placeholder="Enter your motivation for applying to use DIRISA Service"
                              (input)="trimSpaces($event)"></textarea>

                    <label>{{register_form.controls.motivation.value?.length}} Characters (Min: 10, Max: 300)</label>
                    <label *ngIf="register_form.get('motivation')?.invalid && formCheck" style="color: red">
                        Please enter a descriptive motivation.
                    </label>
                    <label *ngIf="leadingAndTrailingSpaces && formCheck" style="color: red">
                        Please remove leading and trailing spaces.
                    </label>
                </div>

                <div class="field" [class.error]="(myPasswordStrength < 75) && formCheck">
                    <label>Password</label>

                    <div class="ui action input">
                        <input [type]="isPasswordVisible ? 'text' : 'password'"
                               formControlName="password"
                               (keyup)="password_check()"
                               placeholder="Enter your password">
                        <button class="ui icon button" type="button" (click)="show_hide_password()">
                            <i class="eye slash outline icon"></i>
                        </button>
                    </div>
                    <label *ngIf="(myPasswordStrength < 75) && formCheck" style="color: red">
                        Please enter a password that meets the requirements below:
                    </label>
                </div>

                <div class="ui tiny progress indicating" id="password_strength_count">
                    <div class="bar"></div>
                    <div class="label" style="text-align: left">Password Strength: {{password_check()}}</div>
                </div>

                <div class="ui divided list">
                    <div class="item">
                        <i class="check icon green" *ngIf="includeLowerCase && includeUpperCase"></i>
                        <i class="close icon red" *ngIf="!(includeLowerCase && includeUpperCase)"></i>
                        At least one lowercase and one uppercase character
                    </div>
                    <div class="item">
                        <i class="check icon green" *ngIf="includeNumber"></i>
                        <i class="close icon red" *ngIf="!includeNumber"></i>
                        At least one number (0-9)
                    </div>
                    <div class="item">
                        <i class="check icon green" *ngIf="includeSymbol"></i>
                        <i class="close icon red" *ngIf="!includeSymbol"></i>
                        At least one special character (!@#$%^&*)
                    </div>
                    <div class="item">
                        <i class="check icon green" *ngIf="hasEightCharacters"></i>
                        <i class="close icon red" *ngIf="!hasEightCharacters"></i>
                        At least 8 characters
                    </div>
                </div><br />

                <button type="button" class="ui button orange fluid" (click)="show_terms_and_conditions()">Continue</button>

            </form>
        </div>
    </div>
</div>

<div class="ui modal" id="tc_modal" style="width: 937px;">
    <div class="header">
        Terms of Agreement
    </div>
    <div class="content" style="font-size: 16px; max-height: 300px; overflow-y: scroll; overflow-x: hidden">
        <div id="terms_and_condition">
            <div class="ui toggle checkbox">
                <input type="checkbox" id="terms_checkbox">
                <label for="terms_checkbox">
                    I have read and accept the <a href="https://www.dirisa.ac.za/wp-content/uploads/2023/05/DIRISA-Services-Ts-and-Cs-v1.0-2023.pdf"
                                                  target="_blank" style="font-weight: 510;">DIRISA Terms and Conditions Policy</a>.
                </label>
            </div>
            <br/>
            <label *ngIf="isTermConditionCheckedError" style="color: red">
                Please agree to the Terms and Conditions.
            </label>
        </div>
        <br>
        <div id="storage_privacy">
            <div class="ui toggle checkbox">
                <input type="checkbox" id="privacy_checkbox">
                <label for="privacy_checkbox">
                    In compliance with the Protection of Personal Information Act (POPIA) No 4 of 2013, I have read and accept the <a href="https://www.dirisa.ac.za/wp-content/uploads/2023/05/Privacy-Statement-for-DIRISA-Services-v1.0-2023.pdf"
                                                                                                                                      target="_blank" style="font-weight: 510;">DIRISA Privacy Notice</a>.
                </label>
            </div>
            <br/>
            <label *ngIf="isStoragePrivacyCheckedError" style="color: red">
                Please agree to the Privacy Notice.
            </label>
        </div>
        <br>
        <div id="storage_policy">
            <div class="ui toggle checkbox">
                <input type="checkbox" id="storage_policy_checkbox">
                <label for="storage_policy_checkbox">
                    I have read and accept the <a href="https://www.dirisa.ac.za/wp-content/uploads/2022/04/Storage-Policy-2022.pdf"
                                                  target="_blank" style="font-weight: 510;">DIRISA Storage Policy</a>.
                </label>
            </div>
            <br/>
            <label *ngIf="isStoragePolicyCheckedError" style="color: red">
                Please agree to the Storage Policy.
            </label>
        </div>
    </div>
    <div class="content">
        <div class="ui divider"></div>

        <form class="ui form" [formGroup]="terms_and_conditions_from">
            <div class="field">
                <re-captcha (resolved)="resolved($event)"
                            siteKey="6Lc2KHkkAAAAAG5woSNQI-E6N4ZDmN9EVfsEmtiA"></re-captcha>
            </div>
            <label *ngIf="isRecaptureCheckedError" style="color: red">
                Please complete the reCAPTCHA.
            </label>
        </form>
    </div>
    <div class="actions">
        <button class="ui button compact" (click)="close_tc_modal()">Cancel</button>
        <button class="ui button compact orange" (click)="register_form_submit()" [ngClass]="{'loading disabled': is_loading}">OK</button>
    </div>
</div>



<app-footer></app-footer>
