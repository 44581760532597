import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {StorageRequest} from "../../model/Storagerequest";
import {User} from "../../model/User";


@Injectable({
  providedIn: 'root'
})
export class StorageRequestService {

  constructor(private httpClient:HttpClient, private router:Router ) { }


    send_requester_to_queue(data: any) {
        return this.httpClient.post<StorageRequest>(environment.base + 'storage/createstoragerequest', data);
    }

    get_all_requests(username:any) {
        return this.httpClient.get<StorageRequest[]>(environment.base + 'admin/getallrequests');
    }

    get_all_storage_requests() {
        return this.httpClient.get<StorageRequest[]>(environment.base + 'storage/getallstoragerequest');
    }

    get_requests_by_username(username:any) {
        return this.httpClient.get<StorageRequest[]>(environment.base + 'storage/getstoragerequests/'+username);
    }
   
    send_approval_or_rejection_to_requester(data: any) {
        return this.httpClient.put<StorageRequest>(environment.base + 'admin/notifyrequesterofapprovalorrejection', data);
    }

    get_requests(start:number, count:number) {
        return this.httpClient.get<StorageRequest[]>(environment.base + 'admin/getallrequests'+start+'/'+count);
    }

}
