import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FailedEmails } from 'src/app/model/FailedEmails';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';

@Component({
  selector: 'app-failed-email-list',
  templateUrl: './failed-email-list.component.html',
  styleUrls: ['./failed-email-list.component.css']
})
export class FailedEmailListComponent {
  
  administrator: FailedEmails[] | undefined;
  itemsPerPage: any;
  failedE_list: FailedEmails[] = []
  failedEmails: FailedEmails[] = [];
  failedEmail: FailedEmails | undefined;
  isButtonDisabled: boolean | undefined;
  
userId: any;
constructor(private administration : AdministratorService, private router : Router){

}
ngOnInit(): void {
  this.addEmail() 
}
redirectToFailedEmailList() {
  this.router.navigate(['/admin/getFailedEmails'])
  //window.location.href="/admin/getFailedEmails"
}

currentPage = 0;
get currentItems(): FailedEmails [] {
  const startIndex = this.currentPage * this.itemsPerPage;
  return this.failedE_list.slice(startIndex, startIndex + this.itemsPerPage);
}
  
 addEmail() {
  this.administration.addEmailList().subscribe(data => {
    this.failedEmails = data;
      },
      (error) => { 
      }
  );
}
ResendEmail(email: FailedEmails, index: number) {
    this.administration.sendEmail(email.id).subscribe(data =>{
this.failedEmail = data;
    }
    );
    alert("Email has been sent!" )
      this.redirectToFailedEmailList();
      window.location.reload();
}
}