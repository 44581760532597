<div class="ui center aligned grid" id="side-button">
       <div class="left floated center aligned six wide column" style="padding-right: 60px;">
              <div class="ui orange button" style="background-color: #f2711c;  border-radius: 50px; color: #ffffff; padding: 10px; margin-right: 10px; font-weight: 600;" (click)="createHelpRequest()" title="Submit any questions,issues or comments">Ask Question</div>
              <a class="side-link" href="https://service.dirisa.ac.za/wiki#/" aria-label="DIRISA Wiki" target="_blank">DIRISA Wiki</a>

       </div>
</div>
<div *ngIf="this.router.url != '/register'" class="ui center aligned grid" id="footer">
       <div class="left floated center aligned six wide column" style="padding: 0px 60px 0px 0px;">
              <a class="footer-link" style="border: none;" href="https://www.dirisa.ac.za/wp-content/uploads/2023/05/DIRISA-Services-Ts-and-Cs-v1.0-2023.pdf" aria-label="DIRISA Ts&Cs" target="_blank">Terms and Conditions Policy</a>

         <a class="footer-link" href="https://www.dirisa.ac.za/wp-content/uploads/2023/05/Privacy-Statement-for-DIRISA-Services-v1.0-2023.pdf" aria-label="Privacy Policy" target="_blank">Privacy Statement</a>
              <a class="footer-link" href="https://www.dirisa.ac.za/wp-content/uploads/2022/04/Storage-Policy-2022.pdf" aria-label="Storage Policy" target="_blank">Storage Usage Policy</a>

       </div>
</div>

<div class="ui modal small" id="showcreatehelper" >
       <a class="ui button compact" style="float: right" (click)="createHelpRequestClose()">X</a>
    <div class="header">Please provide us with any comments or issues.</div>
    <div class="content">
       <form class="ui form" (ngSubmit)="getHelpFrom.form.valid && onSubmit()" #getHelpFrom ="ngForm">
                  <div class="field">
                     <label>Email:</label>
                    <input type="email" [(ngModel)]="helpRequest.requestEmail" name="requestEmail" placeholder="Enter Your Email Address" #requestEmail="ngModel" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
                    <label *ngIf="(getHelpFrom.submitted) && requestEmail.errors?.['required']" style="color: red">
                        Please enter your email address as required.
                    </label>

                    <label *ngIf="requestEmail.errors && (requestEmail.invalid || requestEmail.touched) && requestEmail.errors?.['pattern']" style="color: red">
                        Please enter a valid email address in the format username@dirisa.ac.za
                    </label>

                   </div>
                     <div class="field">
                        <label>Names:</label>
                       <input type="text" [(ngModel)]="helpRequest.requestName" name="requestName" placeholder="Enter Your FirstNames" #requestName="ngModel" required>
                       <label *ngIf="(getHelpFrom.submitted) && requestName.errors?.['required']" style="color: red">Please enter your name as required.</label>
                     </div>
                     <div class="field">
                            <label>Surname:</label>
                           <input type="text" [(ngModel)]="helpRequest.requestSurname" name="requestSurname" placeholder="Enter Your LastName"  #requestSurname="ngModel" required>
                           <label *ngIf="(getHelpFrom.submitted) && requestSurname.errors?.['required']" style="color: red">Please enter your surname as required.</label>
                     </div>

              <div class="ui form">
                  <div class="field">
                    <label>Issue:</label>
                    <textarea rows="2" [(ngModel)]="helpRequest.issue" name="issue" placeholder="Enter your comment or issues" #issue="ngModel" maxlength="5000" required></textarea>
                    <label *ngIf="issue.value?.length">
                    {{issue.value.length}} Characters (Max: 5000)
                    </label>
                    <label *ngIf="(getHelpFrom.submitted) && issue.errors?.['required']" style="color: red">
                        Please enter your surname as required.
                    </label>
                  </div>
                  </div>
                  <br/><br/>
           <button class="ui orange button" type="submit">Submit</button>
           <br/><br/>
          </form>


   </div>
   </div>

