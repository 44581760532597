import { Component } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './preventdoublecomfirmation.component.html',
  styleUrls: ['./preventdoublecomfirmation.component.css']
})
export class DefaultComponent {

}
