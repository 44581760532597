<div class="ui secondary  menu large" style="background-color: rgba(255,255,255,0.9)">
    <a class="item">
        <img src="../../assets/images/DIRISA_logo_RGB.png" style="width: 120px" >
    </a>

    <div class="right menu">
        <!--<div class="item">
            <div class="ui icon input">
                <input type="text" placeholder="Search...">
                <i class="search link icon"></i>
            </div>
        </div>-->
        <a class="item" style="font-weight: bold" (click)="go_to_login()">
            Log In
        </a>
        <a class="item" style="font-weight: bold" (click)="go_to_register()">
            Register
        </a>

    </div>
</div>
