import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetHelp } from 'src/app/model/gethelp';
import { GothelpService } from 'src/app/services/gothelp/gothelp.service';
declare var  $:any;
declare var  toastr:any;
@Component({
  selector: 'app-gethelp-update',
  templateUrl: './gethelp-update.component.html',
  styleUrls: ['./gethelp-update.component.css']
})
export class GethelpUpdateComponent implements OnInit {
  errorMessage: any;
  id!: number;
  updateGetHelpRequest: GetHelp = new GetHelp();

  constructor(private getHelpService: GothelpService,
    private route: ActivatedRoute, private router: Router) { }

  private getHelpById() {
    this.id = this.route.snapshot.params['id'];
    this.getHelpService.getGotHelpById(this.id).subscribe({
      next: (data) => {
        this.updateGetHelpRequest = data;
      },
      error: (e) => {
      }
    });
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getHelpById();
  }
  updateHelp() {
    this.getHelpService.updateGotHelp(this.id, this.updateGetHelpRequest).subscribe({
      next: (data) => {
        toastr.options = {
          "progressBar": true,
          "positionClass": "toast-top-right"
      }
      toastr["success"]("success","You have reply "+ this.updateGetHelpRequest.requestName+" " +this.updateGetHelpRequest.requestSurname +" successfully");
        this.redirectToUserList();
      },
      error: (e) => {
      }
    });
  }
  redirectToUserList() {
    this.router.navigate(['/admin/gethelp']);
  }
  onSubmit() {
    this.updateHelp();
  }
  backToList(){
    this.router.navigateByUrl('/admin/gethelp');
  }
}