<app-index-top-menu></app-index-top-menu>

<div style="margin-top: 10px">
    <div class="ui segment basic " *ngIf="initialLoad" style="height: 70vh">
        <div class="ui active inverted dimmer">
            <div class="ui text loader">Loading</div>
        </div>
        <p></p>
    </div>

    <div class="ui segment basic " *ngIf="logInLoading" style="height: 70vh">
        <div class="ui active inverted dimmer">
            <div class="ui text loader">Logging In</div>
        </div>
        <p></p>
    </div>


    <div class="columns"  style="height: 80vh" *ngIf="!initialLoad && currentUser == undefined">
        <div class="column" >

        </div>
        <div class="column"  >

            <img src="../../../assets/images/ORCID_logo_with_tagline.png" alt="ORCID logo" height="50" style="margin: auto">

            <form class="ui form" [formGroup]="registerForm"   style="margin-top: 10px">
                <div class="field disabled" [class.error]="this.registerForm.get('name')?.invalid && formCheck">
                    <label>Name</label>
                    <input type="text" formControlName="name" autofocus>
                    <label *ngIf="this.registerForm.get('name')?.invalid && formCheck" style="color: red">Please enter a name, e.g., John.</label>
                </div>

                <div class="field disabled" [class.error]="this.registerForm.get('surname')?.invalid && formCheck">
                    <label>Surname</label>
                    <input type="text" formControlName="surname" autofocus>
                    <label *ngIf="this.registerForm.get('surname')?.invalid && formCheck" style="color: red">Please enter a surname, e.g., Doe.</label>
                </div>

                <div class="field disabled" [class.error]="this.registerForm.get('email')?.invalid && formCheck">
                    <label>Email</label>
                    <input type="text" formControlName="email" autofocus>
                    <label *ngIf="this.registerForm.get('email')?.invalid && formCheck" style="color: red">Please enter an email address, e.g., johndoe@csir.co.za.</label>
                </div>

                <div class="field" [class.error]="this.registerForm.get('institution')?.invalid && formCheck">
                    <label>Institution</label>
                    <select class="ui dropdown" formControlName="institution" id="safireInstitution">
                        <option value=""></option>
                        <ng-container *ngFor="let t of institutionList">
                            <option value="{{t.id}}">{{t.name}}</option>
                        </ng-container>
                    </select>
                    <label *ngIf="this.registerForm.get('institution')?.invalid && formCheck" style="color: red">Please select an institution.</label>
                </div>

                <div class="field" [class.error]="(this.registerForm.get('motivation')?.invalid && formCheck) || (leadingAndTrailingSpaces && formCheck)">
                    <label>Motivation</label>
                    <textarea rows="2" formControlName="motivation" maxlength="300" (input)="onInput($event);trimSpaces($event)"></textarea>
                    <label>{{charCount}} characters (Min: 10, Max: 300)</label>
                    <label *ngIf="this.registerForm.get('motivation')?.invalid && formCheck" style="color: red">Please enter a descriptive motivation.</label>
                    <label *ngIf="leadingAndTrailingSpaces && formCheck" style="color: red">Please remove leading and trailing spaces.</label>
                </div>

                <button  [ngClass]="{'loading disabled': registerFormLoading}" class="ui button orange fluid" (click)="openTermsAndCondition()">Continue</button>
            </form>

            <div class="ui green message"  *ngIf="registrationSuccessful">
                <i class="icon frown outline"></i>
                Your account has been successfully registered. Please check your email and confirm. You will be redirected in {{countdown}} seconds
            </div>

        </div>
        <div class="column" >

        </div>
    </div>


    <div class="columns" *ngIf="currentUser != undefined && !currentUser.orcidRegistered">
        <div class="column" >

        </div>
        <div class="column" style="margin-top: 20px" >

            <img src="../../../assets/images/ORCID_logo_with_tagline.png" alt="ORCID logo" height="60" style="margin: auto">

            <div class="ui message large yellow" *ngIf="currentUser?.status == 'APPROVED'">
                <div class="content" style="text-align: center">
                    <i class="exclamation triangle icon big" style="margin: auto"></i>
                </div>
                <div class="ui divider"></div>
                <div class="content">
                    <div class="header">
                        The user details already exist
                    </div>
                    <p style="font-size: large">
                        The email address, {{currentUser.email}}, is currently registered. Do you wish to overwrite your current account with ORCID?
                    </p>
                </div>
                <div class="ui divider"></div>
                <div class="content">

                    <button class="ui button primary" (click)="overrideUserWithOrcid()" [ngClass]="{'loading disabled': isOverrideLoading}" >Yes</button>
                    <button class="ui button red"  (click)="goToHome()" >No</button>

                </div>
            </div>

            <div class="ui icon warning message"  *ngIf="currentUser?.status == 'PENDING'">
                <i class="exclamation triangle icon"></i>
                <div class="content">
                    <div class="header">Note</div>
                    <p>Your account {{currentUser.email}} is waiting for approval. A notification will be sent to your email {{currentUser.email}}.</p>
                </div>
            </div>

            <div class="ui error message"  *ngIf="currentUser?.status == 'REJECTED'">
                <i class="icon frown outline"></i>
                Sorry, {{currentUser.firstName | titlecase}}. Your account {{currentUser.email}} was not approved. For further inquiries, please contact the admin at dirisa@csir.co.za.
            </div>
            <div class="ui error message"  *ngIf="currentUser?.status == 'DECLINED'">
                <i class="icon frown outline"></i>
                Sorry, {{currentUser.firstName | titlecase}}. Your account {{currentUser.email}} email confirmation was unsuccessful. For further inquiries, please contact the admin at dirisa@csir.co.za.
            </div>

            <div class="ui icon blue message" *ngIf="currentUser?.status == 'CONFIRMED'">
                <i class="icon smile outline"></i>
                <div class="content">
                    <div class="header">Confirmed</div>
                    Sorry, {{currentUser.firstName | titlecase}}. Your account {{currentUser.email}} was not approved. For further inquiries, please contact the admin at dirisa@csir.co.za.
                </div>
            </div>

        </div>
        <div class="column" >
        </div>
    </div>
</div>



<div class="columns" *ngIf="currentUser?.orcidRegistered == true">
    <div class="column" >

    </div>
    <div class="column" style="margin-top: 20px"  *ngIf="currentUser?.status == Status.PENDING || currentUser?.status == Status.CONFIRMED">

        <img src="../../../assets/images/ORCID_logo_with_tagline.png" alt="ORCID logo" height="60" style="margin: auto">

        <div class="ui message large yellow" >
            <div class="content" style="text-align: center">
                <i class="exclamation triangle icon big" style="margin: auto"></i>
            </div>
            <div class="ui divider"></div>
            <div class="content">
                <div class="header">
                    User Pending
                </div>
                <p style="font-size: large">
                    Your account is currently waiting for approval.
                </p>
            </div>
        </div>
    </div>

    <div class="column" style="margin-top: 20px"  *ngIf="currentUser?.status == Status.DECLINED">

        <img src="../../../assets/images/ORCID_logo_with_tagline.png" alt="ORCID logo" height="60" style="margin: auto">

        <div class="ui message large red" >
            <div class="content">
                <div class="header">
                    Declined confirmation
                </div>
                <p style="font-size: large">
                    You declined the email confirmation. You won't be able to access DIRISA services.
                </p>
            </div>
        </div>
    </div>
    <div class="column" >

    </div>
</div>



<div class="ui modal scrolling" id="orcid_missing_private_data_modal">
    <div class="header">Data Not Accessible</div>
    <div class="content scrolling" style="font-size: larger">

        Email Address: The primary email address should be visible to everyone if you have added more than one email address.

        <ul>
            <li>Email: The primary email if you have added more than one email address.</li>
            <li>Names</li>
            <li>Biography: This is not mandatory but recommended for visibility.</li>
        </ul>
        <p>Green eye <i class="eye icon green large"></i> in ORCID means that the field is accessible to everyone.</p>
        <p>Yellow eye <i class="eye icon yellow large"></i> in ORCID means that the field is accessible to specified apps.</p>
        <p>Red eye <i class="eye icon red large"></i> in ORCID means that the field is not accessible.</p><br>

    </div>
    <div class="actions">
        <div class="ui button grey" (click)="goToHome()">Cancel</div>
    </div>
</div>


<div class="ui modal" id="termsAndConditionOrcidModal" style="width: 937px;">
    <div class="header">
        Terms of Agreement
    </div>
    <div class="content" style="font-size: 16px; max-height: 300px; overflow-y: scroll; overflow-x: hidden">
        <div id="terms_and_condition">
            <div class="ui toggle checkbox">
                <input type="checkbox">
                <label>
                    I have read and accept the
                    <a href="https://www.dirisa.ac.za/wp-content/uploads/2023/05/DIRISA-Services-Ts-and-Cs-v1.0-2023.pdf"
                       target="_blank"
                       style="font-weight: 510;">
                        DIRISA Terms and Conditions Policy.
                    </a>
                </label>
            </div><br/>
            <label *ngIf="isTermConditionCheckedError" style="color: red">Please agree to the Terms and Conditions.</label>

        </div>
        <br>
        <div id="storage_privacy">
            <div class="ui toggle checkbox">
                <input type="checkbox" id="privacy-checkbox">
                <label for="privacy-checkbox">
                    In compliance with the Protection of Personal Information Act (POPIA) No 4 of 2013, I have read and accept
                    <a href="https://www.dirisa.ac.za/wp-content/uploads/2023/05/Privacy-Statement-for-DIRISA-Services-v1.0-2023.pdf"
                       target="_blank"
                       style="font-weight: 510;">
                        DIRISA’s Privacy Notice.
                    </a>
                </label>
            </div><br />
            <label *ngIf="isStoragePrivacyCheckedError" style="color: red">
                Please agree to the Privacy Notice.
            </label>
        </div>
        <br>
        <div id="storage_policy">
            <div class="ui toggle checkbox">
                <input type="checkbox" id="storage-policy-checkbox">
                <label for="storage-policy-checkbox">
                    I have read and accept
                    <a href="https://www.dirisa.ac.za/wp-content/uploads/2022/04/Storage-Policy-2022.pdf"
                       target="_blank"
                       style="font-weight: 510;">
                        DIRISA’s Storage Policy.
                    </a>
                </label>
            </div><br />
            <label *ngIf="isStoragePrivacyCheckedError" style="color: red">
                Please agree to the Storage Policy.
            </label>
        </div>
    </div>
    <div class="content">
        <div class="ui divider"></div>

        <form class="ui form" [formGroup]="terms_and_conditions_from">
            <div class="field">
                <re-captcha (resolved)="resolved($event)"
                            siteKey="6Lc2KHkkAAAAAG5woSNQI-E6N4ZDmN9EVfsEmtiA"></re-captcha>
            </div>
            <label *ngIf="isStoragePrivacyCheckedError" style="color: red">
                Please complete the reCAPTCHA option displayed.
            </label>
        </form>
    </div>
    <div class="actions">
        <button class="ui button compact" (click)="goToHome()">Cancel</button>
        <button class="ui button compact orange" (click)="registerFormSubmit()" [ngClass]="{'loading disabled': registerFormLoading }">OK</button>

    </div>
</div>





<app-footer></app-footer>
