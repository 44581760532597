import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import decodeToken from "@leteu/jwt-decoder";
import {lastValueFrom} from "rxjs";
import {Institution} from "../model/Institution";
import {InstitutionService} from "../services/institution/institution.service";
import {SafireUser} from "../model/SafireUser";
import {FormBuilder, Validators} from "@angular/forms";
import {SafireService} from "../services/safire/safire.service";
import {Status, User} from "../model/User";
import {AuthenticationService} from "../services/auth/authentication.service";
import {noOnlySpacesValidator} from "../register/register.component";

declare var  $:any

@Component({
  selector: 'app-safire',
  templateUrl: './safire.component.html',
  styleUrls: ['./safire.component.css']
})
export class SafireComponent implements OnInit, AfterViewInit  {

    institutionList: Institution[] = []
    safireUser:SafireUser = new SafireUser(null, null, null, null, null, null)
    formCheck: boolean = false
    isLoading: boolean = false
    currentUser: User | undefined
    initialLoad: boolean = true
    isOverrideLoading: boolean = false
    registerFormLoading: boolean = false
    registrationSuccessful: boolean = false
    logInLoading: boolean = false

    isTermConditionCheckedError: boolean = false;
    isStoragePrivacyCheckedError: boolean = false;
    isStoragePolicyCheckedError: boolean = false;
    isRecaptureCheckedError: boolean = false;
    private isRecapture: boolean = false;

    leadingAndTrailingSpaces: boolean = false

    termsAndConditionsAccepted: boolean = false
    storagePrivacyAccepted: boolean = false
    storagePolicyAccepted: boolean = false
    countdown: number = 5
    charCount: number = 0;
    terms_and_conditions_from = this.formBuilder.group({
        // motivation: ['', [Validators.required, Validators.max(200)]]
    });


    constructor(private activatedRoute: ActivatedRoute,
                private router:Router,
                private institutionService:InstitutionService,
                private formBuilder:FormBuilder,
                private safireService:SafireService,
                private authenticationService:AuthenticationService
                ) {}


    registerForm = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        name: [null,[ Validators.required]],
        surname: [null,[ Validators.required]],
        motivation: [null,[ Validators.required, Validators.minLength(8), Validators.maxLength(300), noOnlySpacesValidator()]],
        institution: [null,[ Validators.required]],
    });


    onInput(event: Event): void {
      const target = event.target as HTMLTextAreaElement;
      this.charCount = target.value.length;
    }
    ngOnInit(): void {

        let token = this.activatedRoute.snapshot.paramMap.get('token')

        if (token != null) {
            try {
                let decodedUser: any = decodeToken(token)

                this.safireUser.password = decodedUser['sub']
                this.safireUser.email = decodedUser['email']
                this.safireUser.name = decodedUser['given_name']
                this.safireUser.surname = decodedUser['family_name']

                // @ts-ignore
                this.registerForm.controls.email.setValue(this.safireUser.email)
                // @ts-ignore
                this.registerForm.controls.name.setValue(this.safireUser.name)
                // @ts-ignore
                this.registerForm.controls.surname.setValue(this.safireUser.surname)
            }catch (e){}
        }

        //load institutions list
        lastValueFrom(this.institutionService.read_all()).then((data) => {
            this.institutionList = data
        }).catch(error => {
        }).finally(() => {
            setTimeout(()=> {
                $('#safireInstitution').dropdown({direction: 'upward'});
            }, 1000);

        })


    }

    ngAfterViewInit(): void {
        lastValueFrom(this.safireService.getUser(this.safireUser.email)).then((users:User[]) => {
            console.log(users)
            if(users.length != 0){
                this.currentUser = users[0]
                //log in
                if(this.currentUser.safireRegistered){

                    if(this.currentUser?.status == "APPROVED"){
                        this.logInLoading = true
                        this.logInFormSubmit()
                    }
                }
            }else{
                //register
                this.initialLoad = false
            }
        }).catch(error => {
        }).finally(() => {
            this.initialLoad = false

            if(this.currentUser?.status != "APPROVED"){
                localStorage.removeItem("access_token")
            }
        })
    }

    resolved(captchaResponse: string) {
        this.isRecapture = true;
        this.isRecaptureCheckedError = false;
    }

    logInFormSubmit() {
        lastValueFrom(this.authenticationService.logIn({
            "username": this.safireUser.email,
            "password": this.safireUser.password
        })).then(data=>{
            // @ts-ignore
            localStorage.setItem('access_token',data['access_token']);
            this.pageRouter()
        })
    }

    pageRouter(){

        lastValueFrom(this.authenticationService.getUserProfile()).then((user: User) => {
            this.currentUser = user

            if (this.currentUser.status == Status.APPROVED && this.currentUser.roles[0].name == "ROLE_USER") {
                this.router.navigate(['home']).then();
            }

            if (this.currentUser.status == Status.APPROVED && this.currentUser.roles[0].name == "ROLE_ADMIN") {
                this.router.navigate(['admin/home']).then();
            }
        }).finally(()=>{
            this.logInLoading = false
        })
    }

    overrideUserWithSafire() {
        this.isOverrideLoading = true
        lastValueFrom(this.safireService.overrideUserWithSafire(this.safireUser.email, this.safireUser.password)).then((user:User)=>{
            this.router.navigate(["home"])
        }).finally(()=>{
            this.isOverrideLoading = false
        })
    }

    close_tc_modal() {
        $('#termsAndConditionSafireModal').modal('hide');
    }

    registerFormSubmit() {
        this.formCheck = true

        if (!this.isRecapture) {
            this.isRecaptureCheckedError = true;
        }
        if (!this.storagePolicyAccepted) {
            this.isStoragePolicyCheckedError = true;
        }
        if (!this.storagePrivacyAccepted) {
            this.isStoragePrivacyCheckedError = true;
        }
        if (!this.termsAndConditionsAccepted) {
            this.isTermConditionCheckedError = true;
        }

        if(this.registerForm.valid && this.termsAndConditionsAccepted && this.storagePrivacyAccepted && this.storagePolicyAccepted && this.isRecapture) {

            this.registerFormLoading = true

            lastValueFrom(this.safireService.registerWithSafire({
                'name':this.registerForm.controls.name.getRawValue(),
                'surname': this.registerForm.controls.surname.getRawValue(),
                'email': this.registerForm.controls.email.getRawValue(),
                'motivation': this.registerForm.controls.motivation.getRawValue(),
                'password': this.safireUser.password,
                'institutionId': this.registerForm.controls.institution.getRawValue()
            })).then((user:User)=>{
                this.registrationSuccessful = true
            }).finally(()=>{
                this.isOverrideLoading = false
                this.registerFormLoading = false

                setInterval(()=> {
                    // @ts-ignore
                    this.countdown = this.countdown - 1;

                    if (this.countdown == 0) {
                        this.router.navigate([""])
                    }
                }, 1000);

                $('#termsAndConditionSafireModal').modal('hide');
                this.registerForm.reset()
                this.formCheck = false
            })
        }
    }

    protected readonly Status = Status;

    goToHome() {
        this.router.navigate(["home"])
    }

    openTermsAndCondition() {

        this.formCheck = true
        if (this.registerForm.valid && !this.leadingAndTrailingSpaces) {

            $('#termsAndConditionSafireModal').modal('setting', 'closable', false).modal('show')

            $('#terms_and_condition .checkbox ').checkbox({
                onChecked: () => {
                    this.termsAndConditionsAccepted = true;
                    this.isTermConditionCheckedError = false;
                },
                onUnchecked: () => {
                    this.termsAndConditionsAccepted = false;
                    this.isTermConditionCheckedError = true;
                }
            });
            $('#storage_privacy .checkbox ').checkbox({
                onChecked: () => {
                    this.storagePrivacyAccepted = true;
                    this.isStoragePrivacyCheckedError = false;
                },
                onUnchecked: () => {
                    this.storagePrivacyAccepted = false;
                    this.isStoragePrivacyCheckedError = true;
                }
            });
            $('#storage_policy .checkbox ').checkbox({

                onChecked: () => {
                    this.storagePolicyAccepted = true
                    this.isStoragePolicyCheckedError = false;
                },
                onUnchecked: () => {
                    this.storagePolicyAccepted = false;
                    this.isStoragePolicyCheckedError = true;
                }
            });
        }
    }

    trimSpaces(event: Event){
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;

        if (inputValue.startsWith(" ") || inputValue.endsWith(" ")){
            this.leadingAndTrailingSpaces = true
        }else {
            this.leadingAndTrailingSpaces = false
        }
    }
}
