import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetHelpRequest } from '../model/gethelprequest';
import { GothelpService } from '../services/gothelp/gothelp.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var  $:any;
declare var  toastr:any;



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  errorMessage: any;
   helpRequest: GetHelpRequest = new GetHelpRequest();
   showFooter: boolean = true;
  constructor(private gotHelpService: GothelpService, public router: Router) { 
  }
  ngOnInit(): void {
 }
  saveHelper() {
  this.gotHelpService.createGetHelp(this.helpRequest).subscribe({
    next: (data) => {
      toastr.options = {
        "progressBar": true,
        "positionClass": "toast-top-right"
    }
    toastr["success"]("success", "You request is  successfully submitted")
    this.createHelpRequestClose();
    },error: (e) => {
    }
  });
    
}
  createHelpRequest() {
      $('#showcreatehelper').modal('show');
}
createHelpRequestClose(){
  $('#showcreatehelper').modal('hide');
}
onSubmit() {
    this.saveHelper();
  }
  
}







