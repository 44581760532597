<!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body>
  <img src="../../../assets/images/DIRISA_logo_RGB.png" alt="Girl in a jacket" height="70" ><br/><br/>

  <div class="ui red message">
    <div class="header">
      Thank you!
    </div>
    <p></p>We have received your email confirming the decline of your request. No further action will be taken on your application.
  </div>
</body>
</html>
