import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {App} from "../../model/App";

@Injectable({
  providedIn: 'root'
})
export class AppService {

    constructor(private httpClient:HttpClient, private router:Router) {}

    get_apps() {
        return this.httpClient.get<App[]>(environment.base + 'app/');
    }

    remove_app(id:any) {
        return this.httpClient.delete<any>(environment.base + 'app/delete/'+id);
    }

    update_app(data: any) {
        return this.httpClient.put<App>(environment.base + 'app/update', data);
    }
}
