import { Component,EventEmitter,Input,OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { AdminRoleTypes } from 'src/app/model/AdminRoleTypes';
import { Administrator } from 'src/app/model/Administrator';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { AdminroletypeService } from 'src/app/services/adminroletype/adminroletype.service';
declare var  $:any;
declare var  toastr:any;
@Component({
  selector: 'app-updateadministrator',
  templateUrl: './updateadministrator.component.html',
  styleUrls: ['./updateadministrator.component.css']
})
export class UpdateadministratorComponent implements OnInit {
  errorMessage: any;
  id!: number;
  administrator: Administrator = new Administrator();
  adminRoleTypes: AdminRoleTypes[] | undefined;

  constructor(private administratorService: AdministratorService,private adminRoleTypeService:AdminroletypeService,
    private route: ActivatedRoute, private router: Router) { }

  private getAdminById() {
    this.id = this.route.snapshot.params['id'];
    this.administratorService.getAdministratorUserById(this.id).subscribe({
      next: (data) => {
        this.administrator = data;
      },
      error: (e) => {
      }
    });
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getAdminById();
    this.getAllAdminRoleTypes();
  }
  updateUser() {
    this.administratorService.updateAdministratorUser(this.id, this.administrator).subscribe({
      next: (data) => {
        toastr.options = {
          "progressBar": true,
          "positionClass": "toast-top-right"
      }
      toastr["success"]("success", this.administrator.username+" is updated with role "+this.administrator.type.toLowerCase()+" successfully")
        this.redirectToUserList();
      },
      error: (e) => {
        this.errorMessage = "Username "+this.administrator.email+" already assigned to the role "+this.administrator.type+".";
      }
    });
  }
  redirectToUserList() {
    this.router.navigate(['/admin/administratoruser']);
  }
  onSubmit() {
    this.id =this.administrator.adminId;
    this.updateUser();
  }
  getAllAdminRoleTypes(){
    this.adminRoleTypeService.listAllAdminRoleTypes().subscribe(data =>{
     this.adminRoleTypes = data;
    });
  }
}