import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { NotificationModel } from 'src/app/model/Notification';
import { NotificationRequest } from 'src/app/model/NotificationsRequest';
import { NotificationService } from 'src/app/services/notification/notification.service';
declare var  $:any;
declare var  toastr:any;
@Component({
  selector: 'app-notification-update',
  templateUrl: './notification-update.component.html',
  styleUrls: ['./notification-update.component.css']
})
export class NotificationUpdateComponent implements OnInit{
  id!:number;
 notificationRequest: NotificationModel = new NotificationModel();
  ngOnInit(): void {
   this.getNotificationById();
  }
  constructor(private notificationService: NotificationService,private route: ActivatedRoute, private router: Router){

  }
private getNotificationById() {
    this.id = this.route.snapshot.params['id'];
    this.notificationService.getNotification(this.id).subscribe({
      next: (data) => {
        this.notificationRequest = data;
      },
      error: (e) => {
      }
    });
  }

  updateNotification() {
    this.notificationService.updateNotification(this.id, this.notificationRequest).subscribe({
      next: (data) => {
        toastr.options = {
          "progressBar": true,
          "positionClass": "toast-top-right"
      }
      toastr["success"]("success", " Notification is updated successfully")
        this.redirectToNotificationList();
      },
      error: (e) => {
      }
    });
  }
  redirectToNotificationList() {
    this.router.navigate(['/admin/notifications']);
  }
  onSubmit(){
    this.updateNotification();
  }
}
