import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationModel } from 'src/app/model/Notification';
import { NotificationRequest } from 'src/app/model/NotificationsRequest';
import { NotificationService } from 'src/app/services/notification/notification.service';
declare var  toastr:any;
declare var  $:any;
@Component({
  selector: 'app-notification-create',
  templateUrl: './notification-create.component.html',
  styleUrls: ['./notification-create.component.css']
})
export class NotificationCreateComponent implements OnInit{
notificationRequest :NotificationRequest = new NotificationRequest();
notifications: NotificationModel[] | undefined;
errorMessage?:string;
  ngOnInit(): void {
   this.getAllNotification();
  }
  constructor(private notificationService: NotificationService,private router: Router){

  }
      saveNotification() {
      this.notificationService.saveNotification(this.notificationRequest).subscribe({
        next: (data) => {
          toastr.options = {
            "progressBar": true,
            "positionClass": "toast-top-right"
        }
        toastr["success"]("success", "Manage to save the subject and message successfully");
          this.redirectToNotificationList();
        },error: (e) => {
         
          this.errorMessage = "Failed to save the subject and message.";
        } 
      });
        
    }
    getAllNotification(){
      this.notificationService.getAllNotifications().subscribe(data =>{
        this.notifications = data;
      })
    }
    redirectToNotificationList() {
      this.router.navigate(['/admin/notifications']);
    }
    onSubmit(){
      this.saveNotification();
    }
  }


