import {Component, OnInit} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Deleted, User} from "../model/User";
import {AuthenticationService} from "../services/auth/authentication.service";
import {InstitutionService} from "../services/institution/institution.service";
import {RejectionReason} from "../model/RegectionReason";
import {RejectionService} from "../services/rejection/rejection.service";
import { environment } from 'src/environments/environment';
import { Role } from '../model/UserRole';
import { UpdateRoleRequest } from '../model/Updaterolerequest';
import { UserroleService } from '../services/user-role/userrole.service';
import Swal from "sweetalert2";
import {terminate} from "@angular/fire/firestore";
declare var  toastr:any

declare var  $:any

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {
    errorMessage: any;
    id!: number;
    roles: Role[] | undefined;
    updateRoleRequest: UpdateRoleRequest = new UpdateRoleRequest();
    users_list: User[] = []
    deactivated_user_list: User[] = [];
    current_user: User | undefined = undefined
    rejection_reason_list: RejectionReason[] = []
    current_user_index: number = 0;
    is_reject_loading: boolean = false;
    deleted: Deleted = Deleted.ACTIVE;
    username: any;

    constructor(
        private authenticationService: AuthenticationService,
        private institutionService: InstitutionService,private userroleService: UserroleService,
        private formBuilder: FormBuilder,
        private rejectionService:RejectionService,
        private router:Router,private route: ActivatedRoute) {
    }

    ngOnInit(): void {

        $('#thirdPartyWarningModal').modal('hide');

        this.getAllRoles();
        lastValueFrom(this.authenticationService.get_all_users()).then((users: User[]) => {
            this.users_list = users
        }).then(()=>{
            setTimeout(()=> {
                    //do something special
                $('.ui.dropdown').dropdown();
            }, 1000);
        })

        this.get_all_users()


        setTimeout(()=> {
            $('#user_search')
                .search({
                    type          : 'category',
                    minCharacters : 3,
                    apiSettings   : {
                        onResponse: (serverResponse:User[])=> {
                            if(Object.keys(serverResponse).length === 0) {

                                let users: User[] = []
                                let response = {
                                    results: users
                                };

                                return response
                            }


                                let users: User[] = []
                                this.users_list = []

                                for (let x in serverResponse) {
                                    this.users_list.push(serverResponse[x])
                                    users.push(serverResponse[x])
                                }

                                let response = {
                                    results: users
                                };

                            return response;
                        },
                        url: environment.base+'auth/user/search?query={query}'
                    }
                })
            ;

        }, 5000);
    }


    rejection_reason_form = this.formBuilder.group({
        id: ['',[ Validators.required]]
    });
    open_rejection_modal(user: User | undefined, i: number){

        this.current_user_index = i;

        lastValueFrom(this.rejectionService.getListOfUserRejection()).then(data=>{
            this.rejection_reason_list = data;
        }).finally(()=>{
            $('#reasons').dropdown();
        })

        this.current_user = user;
        $('#users_rejection_modal').modal('show');
        //this.set_user_status(2,u.username,i+'b',i)
    }

    rejection_reason_form_submit() {
        // @ts-ignore
       this.set_user_status(4,this.current_user?.email, this.rejection_reason_form.getRawValue().id)
       toastr.options = {
        "progressBar": true,
        "positionClass": "toast-top-right"
    }
    toastr["success"]("success", (" The user successfully decline"));
       $('#users_rejection_modal').modal('hide');
       this.redirectToUserList();

    }

    set_user_status(status: number, username: string|undefined, i: number, id:any) {
        this.is_reject_loading = true
        lastValueFrom(this.authenticationService.set_user_status({"status":status, "username":username, "reason":this.rejection_reason_form.getRawValue().id})).then((user: User) => {
            this.users_list[i] = user

            toastr.options = {
                "progressBar": true,
                "positionClass": "toast-top-right"
            }
            toastr["success"]("success", user.firstName.toUpperCase()+" successfully approved");
            $('#users_view_modal').modal('hide');
            this.redirectToUserList();
        }).finally(()=>{
            this.is_reject_loading = false
            $('#users_view_modal').modal('hide');
        })
    }

    someMethod($event: string) {
        $('#deactivated_users_modal').modal('show');
        lastValueFrom(this.authenticationService.get_deactivated_users()).then(data=>{
            this.deactivated_user_list = data
        })
    }


    restore_user(username: string) {
        lastValueFrom(this.authenticationService.put_restore_users(username)).then((users: User) => {

        })
    }


    open_profile_image(user: User, index: number) {

        /*lastValueFrom(this.authenticationService.get_user_profile_image(user.email)).then((image:Image)=>{
            // @ts-ignore
            this.current_user.profileImage = image
        })*/

        this.current_user = user
        this.current_user_index = index
        $('#users_view_modal').modal('show');
    }
    btnUserViewClose(): void {
        $('#users_view_modal').modal('hide');
     }

     btnRejectionClose(): void {
        $('#users_rejection_modal').modal('hide');
     }

    itemsPerPage = 10; // Number of items to display per page
    currentPage = 0;

    get currentItems(): User[] {
        const startIndex = this.currentPage * this.itemsPerPage;
        return this.users_list.slice(startIndex, startIndex + this.itemsPerPage);
    }

    nextPage() {
        if (this.currentPage < this.pageCount - 1) {
            this.currentPage++;
        }
    }

    previousPage() {
        if (this.currentPage > 0) {
            this.currentPage--;
        }
    }

    get pageCount(): number {
        return Math.ceil(this.users_list.length / this.itemsPerPage);
    }
    private getAllRoles() {
        this.userroleService.getAllUserRoles().subscribe({
          next: (data) => {
            this.roles = data;
          },
          error: (e) => {
          }
        });
      }
      updateRole() {
        this.userroleService.updateUserRole(this.id,this.updateRoleRequest).subscribe({
          next: (data) => {
            toastr.options = {
                "progressBar": true,
                "positionClass": "toast-top-right"
            }
            toastr["success"]("success", "User Role is updated successfully")
            $('#changeUserRoleModel').modal('hide');
            this.redirectToUserList();
          },
          error: (e) => {
          }
        });
      }

      onSubmit() {
        this.updateRole();
      }
    changeUserRole(id:number,username:string){
        this.updateRoleRequest.username = username;
        this.id = id;
        $('#changeUserRoleModel').modal('show');
    }
    btnClose(){
        $('#changeUserRoleModel').modal('hide');
    }
    redirectToUserList() {
        window.location.href="/admin/home"
      }

    get_all_users() {
        lastValueFrom(this.authenticationService.get_all_users()).then((users: User[]) => {
            this.users_list = users
            $('#user_search_field').val('')
        }).then(()=>{
            setTimeout(()=> {
                //do something special
                $('.ui.dropdown').dropdown();
            }, 1000);
        })
    }

    buttonSoftDeleteUser(user: User, index: number){
        this.authenticationService.softDeleteUser(user.email).subscribe(
            (response: User) => {
            this.currentItems[index].deleted = Deleted.PENDING
    },
    );

    }
    buttonDeclineDeleteUser(user: User, index: number): void{
        this.authenticationService.declineDelete(user.email).subscribe(
            (response: User) =>{
                this.currentItems[index].deleted = Deleted.DECLINED
            },
        );

    }

    buttonHardDeleteUser(user: User, index: number) {
        if (confirm('Are you sure you want to remove this user?')) {
            this.authenticationService.hardDeleteUser(user.email).subscribe(
                () => {
                    this.redirectToUserList();
                },
            );
        }
    }

}

