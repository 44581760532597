<!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body>
  <img src="../../../assets/images/DIRISA_logo_RGB.png" alt="Girl in a jacket" height="70" ><br/><br/>
  <div class="ui green message">
      <div class="header">
          Thank you!
      </div>
      <p>Our records indicate that you have already confirmed or declined your email confirmation.<br>
          Please contact the system administrator at dirisa@csir.co.za for further information.</p>
  </div>

</body>
</html>
