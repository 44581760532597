<!--<app-index-top-menu></app-index-top-menu>-->

<div class="ui grid">
    <div class="ten wide column" style="background-image:url('../../assets/images/login_3.jpg'); background-position: center center; background-size: cover;">

    </div>
    <div class="six wide column is-narrow" style="background-color: rgb(0,0,0,0.02); height: 94vh;">

        <div class="ui center aligned segment basic" style="margin-top: 5vh; padding: 0 50px 0 50px">
            <img src="../../../assets/images/DIRISA_logo_RGB.png" alt="DIRISA logo" height="80" style="margin: 0">
        </div>

        <div class="" style=" margin-top: 8%;">
            <h4 class="ui left aligned header grey" style="margin: 0; padding: 0 50px 0 50px;">Log In to Your Account</h4><br>
        </div>

        <div  style="min-width: 520px; padding: 0 70px 0 30px">
            <form class="ui form" [formGroup]="log_in_form" (ngSubmit)="log_in_form_submit()">
                <div class="field" [class.error]="this.log_in_form.get('email')?.invalid && formCheck">
                    <label>Email</label>
                    <input type="text" formControlName="email" autofocus (blur)="onBlurEmail()">
                    <label *ngIf="this.log_in_form.get('email')?.invalid && formCheck" style="color: red">Please enter an email address, e.g., johndoe@csir.co.za.</label>
                </div>

                <div class="field" [class.error]="this.log_in_form.get('password')?.invalid && formCheck">
                    <label>Password</label>
                    <div class="ui action input">
                        <input  [type]="isPasswordVisible ? 'password' : 'text'" formControlName="password">
                        <button class="ui icon button" type="button" (click)="show_hide_password()">
                            <i class="eye slash outline icon"></i>
                        </button>
                    </div>
                    <label *ngIf="this.log_in_form.get('password')?.invalid && formCheck" style="color: red">Please fill in this password field.</label>

                </div>

                <button [ngClass]="{'loading disabled': isLoading}" type="submit" class="ui button orange fluid">Login</button>
                <br><a class="cursor-link-pointer" (click)="password_reset_modal_open()">Reset Password?</a>

                <!-- <div class="ui horizontal divider">Or</div> -->

                <!--<button class="ui button blue" (click)="signInWithGoogle()">Google Sign In</button>-->

                <div class="ui horizontal divider">
                    Or
                </div>


                <button type="button" class="ui button fluid basic" style="border: 1px solid gray; padding: 7px" (click)="safireProcessFlow()">
                    <img src="assets/images/safire_icon.jpg"  class="ui avatar image" style="height: 20px; width: 20px">
                    <span style="font-weight: bold">Log In/Register with SAFIRE</span>

                </button>

                <a  id="orcid_redirect"  href="https://orcid.org/oauth/authorize?client_id=APP-9ONOG5YAXJTG5BGS&response_type=code&scope=openid&redirect_uri={{environment.orcidRedirectURL}}">
                    <button type="button" class="ui button fluid basic" style="border: 1px solid gray; padding: 7px; margin-top: 20px" >
                        <img src="assets/images/orcid.png"  class="ui avatar image" style="height: 20px; width: 20px">
                        <span style="font-weight: bold">Log In/Register with ORCID</span>
                    </button>
                </a>
                <h6 class="ui header grey small" >Register a new account: <a class="cursor-link-pointer" (click)="go_to_register()">Sign Up</a></h6>
            </form>
        </div>


        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="isLoginUnsuccessful && currentUser?.safireRegistered==false && currentUser?.orcidRegistered==false">
            <i class="icon help"></i>
            Incorrect email or password.
        </div>


        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="currentUser==undefined && isLoginUnsuccessful">
            <i class="icon help"></i>
            User does not exist. Please register first.
        </div>

        <div class="ui warning message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="currentUser?.status == 'PENDING' && currentUser?.safireRegistered==false && currentUser?.orcidRegistered==false">
            <i class="exclamation triangle icon"></i>
            Your account {{currentUser?.email}} is awaiting approval. A notification will be sent to your email {{currentUser?.email}}.
        </div>

        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="currentUser?.status == 'REJECTED' && currentUser?.safireRegistered==false && currentUser?.orcidRegistered==false">
            <i class="icon frown outline"></i>
            Sorry, {{currentUser?.firstName | titlecase}}. Your account {{currentUser?.email}} was not approved.
            For further inquiries, please contact the admin at dirisa@csir.co.za
        </div>
        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="currentUser?.status == 'DECLINED' && currentUser?.safireRegistered==false && currentUser?.orcidRegistered==false">
            <i class="icon frown outline"></i>
            Sorry, {{currentUser?.firstName | titlecase}}.
            Your account {{currentUser?.email}} email confirmation was unsuccessful. For further inquiries, please contact the admin at dirisa@csir.co.za.
        </div>

        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="currentUser?.status == 'CONFIRMED' && currentUser?.safireRegistered==false && currentUser?.orcidRegistered==false">
            <i class="icon frown outline"></i>
            Sorry, {{currentUser?.firstName | titlecase}}.
            Your account {{currentUser?.email}} was not approved. For further inquiries, please contact the admin at dirisa@csir.co.za.
        </div>

    </div>
</div>



<div class="ui modal tiny" id="thirdPartyWarningModal">
    <div class="header">Third Party Warning</div>
    <div class="content scrolling" style="font-size: larger;font-weight: bold">

        <img *ngIf="currentUser?.orcidRegistered" src="../../../assets/images/ORCID_logo_with_tagline.png"  height="60" style="margin: auto">
        <img *ngIf="currentUser?.safireRegistered" src="../../../assets/images/safire-logo.png"  height="60" style="margin: auto">

        <span *ngIf="currentUser?.orcidRegistered">
            <p>You are currently registered with ORCID. Please log in with ORCID.</p>
        </span>
        <span *ngIf="currentUser?.safireRegistered">
            <p>You are currently registered with SAFIRE. Please log in with SAFIRE.</p>
        </span>

    </div>
    <div class="actions">
        <div class="ui button" (click)="closeThirdPartyModal()">Ok</div>
    </div>
</div>



<app-footer></app-footer>
