import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../model/User';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  message: string = '';
  username: any;
  action: any;
  userRequest: User = new User();
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.username = params['username'];
    });

    this.route.url.pipe(takeUntil(this.destroy$)).subscribe(urlSegments => {
      this.action = urlSegments[0].path;
      if (this.action === 'confirmdelete') {
      this.authenticationService.confirmDelete(this.username).subscribe({
        next: (data) => {
          this.userRequest = data;
          if(this.userRequest.deleted =="PENDING"){
            this.message = 'We have received your confirmation. Your account will be deleted within the next two working days.';
          }
          if(this.userRequest.deleted =="DELETED"){
            this.message = 'You have already confirmed your deletion. Please contact us at dirisa@csir.co.za for further assistance';
          }
          if(this.userRequest.deleted =="ACTIVE"){
            this.message = 'Your account deletion request has already been declined. Please contact us at dirisa@csir.co.za for further assistance.';
          }

        },

    });
  }
  if (this.action === 'declinedelete') {
    this.authenticationService.declineDelete(this.username).subscribe({
      next: (data) => {
        this.userRequest = data;
        if(this.userRequest.deleted =="PENDING"){
          this.message = 'We have received your rejection of the deletion request.';
        }
        if(this.userRequest.deleted =="ACTIVE"){
          this.message = 'You have already declined the deletion request. Please contact us at dirisa@csir.co.za for further assistance.';
        }
        if(this.userRequest.deleted =="DELETED"){
          this.message = 'You have already confirmed your deletion. If you need further assistance, please contact us at dirisa@csir.co.za.';
        }
       },

  });
  }
  });
  }}
