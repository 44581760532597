import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetHelp } from 'src/app/model/gethelp';
import { GothelpService } from 'src/app/services/gothelp/gothelp.service';
declare var  toastr:any;
declare var  $:any;
@Component({
  selector: 'app-gethelp-list',
  templateUrl: './gethelp-list.component.html',
  styleUrls: ['./gethelp-list.component.css']
})
export class GethelpListComponent implements OnInit {

  getHelp: GetHelp[] | undefined;
  idNum: number =0;

  constructor(private getHelpService: GothelpService, private router: Router) {

  }

  ngOnInit(): void {
  this.getListOfHelp();
  }

  private getListOfHelp() {
    this.getHelpService.getAllGotHelpList().subscribe(data => {
      this.getHelp = data;
    });
  }
  confirmDelete(id:number){
    this.idNum = id;
    $("#confirmationDeletion").modal("show");
  }
  notSureDelete(){
    $("#confirmationDeletion").modal("hide");
  }
  updateGetHelp(id: number) {
    this.router.navigate(['admin/updatehelp', id]);
  }
  deleteGetHelp(id: number) {
    this.getHelpService.deleteHelp(id).subscribe(data => {
      this.getListOfHelp();
      toastr.options = {
        "progressBar": true,
        "positionClass": "toast-top-right"
    }
    toastr["success"]("success", "The item deleted successfully");
    $("#confirmationDeletion").modal("hide");
    });
  }
}