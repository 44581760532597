let machineEnvironment = 'service.dirisa.ac.za'
export const environment = {

    production: false,

    //base:"http://localhost:8080/api/v1/",
    base:'https://'+machineEnvironment+'/ms-sso/api/v1/',

    entryUrl: 'https://'+machineEnvironment,

    /************* ORCID ********************/
    orcidRedirectURL: 'https://'+machineEnvironment+'/',
    clientID:'APP-9ONOG5YAXJTG5BGS',
    clientSecret:'80952b0b-87bf-40f6-a500-6491958c6357',
    orcidEntryUrl: machineEnvironment,

    /*************  ****SAFIRE****************/
    safireRedirectURL: 'https://'+machineEnvironment+'/',
    safireClientId: machineEnvironment,
    safireIssuer:'https://sso.dirisa.ac.za/realms/nicis',

    /********************REDIRECT URLS*****************/
    dmp:'https://'+machineEnvironment+'/SADMPTool/sso/',
    ddt:'https://'+machineEnvironment+'/metalnx/login/sso?token=',
    minting:'https://'+machineEnvironment+'/mintingdoi/doi/list-minting/',
    adminminting:'https://'+machineEnvironment+'/mintingdoi/doi/admin/list-all-minting/',
    dshare:'https://'+machineEnvironment+'/dshare/collection/mycollection/',
    /*******************REPORTS*********************/
    reportservice:'https://'+machineEnvironment+'/report/admin/home/'

};
