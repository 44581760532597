import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-unsubscribe-activation',
  templateUrl: './unsubscribe-activation.component.html',
  styleUrls: ['./unsubscribe-activation.component.css']
})
export class UnsubscribeActivationComponent implements OnInit{
  username!:string;
  unsubscribeUser!:boolean;
  ngOnInit(): void {
    this.unsubscribeEmailNotification();
  }
  constructor(private notificationService:NotificationService,private router: Router,private route:ActivatedRoute){

  }
  unsubscribeEmailNotification(){
    this.unsubscribeUser =false;
    this.username = this.route.snapshot.params['username']
    this.notificationService.unsubscriptNotification(this.username,this.unsubscribeUser).subscribe(data =>{
      this.redirectToUnsubscribeNotification(this.username);
    });
  }
  redirectToUnsubscribeNotification(username:string) {
    this.router.navigate(['/unsubscribe/'+username]);
  }
}
