import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminRoleTypes } from 'src/app/model/AdminRoleTypes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminroletypeService {

  constructor(private httpClient:HttpClient) {

   }
   listAllAdminRoleTypes():Observable<AdminRoleTypes[]>{
    return this.httpClient.get<AdminRoleTypes[]>(environment.base+"adminroletype/list")

   }
}
