<app-top-navigation></app-top-navigation>

<div class="ui container" style="margin-top: 100px" >
    <h4 class="text-center">Administrator User List</h4>  
    <button style="float: right;margin-bottom: 15px;" (click) = "addAdministratorUser()" class="ui orange button">Assign Admin User</button><br/>
    <table class="ui single line table large selectable">
        <thead>
            <tr>
               <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
                <th>Assigned Date</th>
                <th>Assign</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let admin of administrator">
                <td> {{ admin.firstName }} </td>
                <td> {{ admin.lastName }} </td>
                <td> {{ admin.email }} </td>
                <td> {{ admin.createdAt | date:'d-MMMM-y HH:mm' }} </td>
                <td> {{ admin.type }} </td>
                <td>
                <button (click) = "updateAdministratorUser(admin.adminId)"  class="ui button">Update</button>
                <button (click) = "confirmRevokeUser(admin.adminId,admin.email)"  class="ui teal button">Revoke</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="ui modal small" id="confirmationRevokeUser">
     <div class="header">Revoke Confirmation</div>
    <div class="content">
        <p>Are you sure you want to revoke this user {{username}}?</p>
        <div class="actions">
            <button class="ui green button compact"  (click)="revokeUser(idNum,username)" >Yes</button>
            <button class="ui red button compact" style="float: left" (click)="notSureRevoke()">No</button>
        </div>
    </div>
</div>