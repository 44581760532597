<app-top-navigation></app-top-navigation>

<div class="ui container" style="margin-top: 100px" *ngIf="current_user != null" >

    <form class="ui form" (ngSubmit)="storage_request_form_submit()" >

        <h2 class="ui header">
            <i class="server icon grey"></i>
            <div class="content">
                Storage Request
                <div class="sub header">Request for disk storage from DIRISA</div>
            </div>
        </h2>
        <div class="form-shadow">
            <h4 class="ui medium dividing header">Type Of Storage</h4><br>
            <div class="field" [formGroup]="storage_request_form">
                <label>Select Storage Type</label>
                <select class="ui dropdown icon" formControlName="storage_term" (ngModelChange)="termClickListener()">
                    <option value="SHORT">Data Deposit Tool (DDT)</option>
                    <option value="LONG">Long Term Archive (LTA)</option>
                </select>
                <span class="error" *ngIf="storage_term?.valueOf()" style="color: red">*Storage Type required</span>
            </div>
            <div class="content">
                <div class="two fields">
                    <div class="field"  *ngIf="this.term_click_listener == true" [formGroup]="storage_request_form_ddt">
                        <label>What is the plan for data retirement?</label>
                        <input type="text"  formControlName="storage_data_retirement" >
                        <span class="error" *ngIf="storage_data_retirement?.valueOf()" style="color: red">*Retirement Data Plan required</span>
                        <!--{{storage_data_retirement}} :{{storage_request_form_ddt?.invalid}}-->
                    </div>
                    <div class="field" *ngIf="this.term_click_listener == true" [formGroup]="storage_request_form_ddt">
                        <label>What is the plan for data preservation?</label>
                        <input type="text"  formControlName="storage_data_preservation" >
                        <span class="error" *ngIf="storage_data_preservation?.valueOf()" style="color: red">*Data Preservation required</span>
                    </div>
                </div>
                <div class="field" *ngIf="this.term_click_listener == true" [formGroup]="storage_request_form_ddt">
                    <div class="ui yellow message"> <p>NB:Please ensure that you have completed a DMP and marked it as public, as part of your motivation. Please share the title of the DMP to ensure that the storage request approver can find and review it.</p></div>
                    <label>Motivation</label>
                    <textarea  type="text" rows="2" formControlName="project_motivation_ddt" maxlength="3500"></textarea>
                    <label>{{this.storage_request_form_ddt.controls.project_motivation_ddt.value?.length}} Characters (Min 10 Characters and Max Characters 3500)</label>
                    <span class="error" *ngIf="project_motivation_ddt?.valueOf()" style="color: red">*More descriptive motivation required</span>
                </div>
                <div class="ui cards" *ngIf="this.term_click_listener == false" [formGroup]="storage_request_form_lta">
                    <div class="card" style="width: 500px">
                        <div class="content">
                            <div class="header">
                                Primary Custodian
                            </div>
                            <div class="meta">
                                Information of Primary Custodian
                            </div>
                            <div class="field">
                                <label>First Name:</label>
                                <div class="ui input">
                                    <input readonly placeholder="{{ current_user.firstName }}" type="text">
                                </div>
                            </div>
                            <div class="field">
                                <label>Last Name:</label>
                                <div class="ui input">
                                    <input readonly placeholder="{{ current_user.lastName }}" type="text">
                                </div>
                            </div>
                            <div class="field">
                                <label>Email:</label>
                                <div class="ui input">
                                    <input readonly placeholder="{{ current_user.email }}" type="text">
                                </div>
                            </div>
                            <div class="field">
                                <label>Institution:</label>
                                <div class="ui input">
                                    <input readonly placeholder="{{ current_user.institution.name }}" type="text">
                                </div>
                            </div>
                            <div class="field" >
                                <label>Division/Department:</label>
                                <input type="text"  formControlName="primary_custodian_department" >
                                <span class="error" *ngIf="primary_custodian_department?.valueOf()" style="color: red">*Division/Department required</span>
                            </div>
                            <div class="field">
                                <label>Phone Number:</label>
                                <input type="number" formControlName="primary_custodian_phone_number" required appMaxLength="10" >
                                <span class="error" *ngIf="primary_custodian_phone_number?.valueOf()" style="color: red">*Primary Custodian Phone Number required</span>
                            </div>
                        </div>
                    </div>
                    <div class="card" style="width: 500px">
                        <div class="content">
                            <div class="header">
                                Secondary Custodian
                            </div>
                            <div class="meta">
                                Information of Secondary Custodian
                            </div>
                            <div class="field">
                                <label>First Name:</label>
                                <input type="text"  formControlName="secondary_custodian_first_name" >
                                <span class="error" *ngIf="secondary_custodian_first_name?.valueOf()" style="color: red">*Secondary Custodian name required</span>
                            </div>
                            <div class="field">
                                <label>Last Name:</label>
                                <input type="text"  formControlName="secondary_custodian_last_name" >
                                <span class="error" *ngIf="secondary_custodian_last_name?.valueOf()" style="color: red">*Secondary Custodian surname required</span>
                            </div>
                            <div class="field">
                                <label>Email:</label>
                                <input type="email"  formControlName="secondary_custodian_email" >
                                <span class="error" *ngIf="secondary_custodian_email?.valueOf()" style="color: red">*Secondary Custodian email required or invalid email entered</span>

                            </div>
                            <div class="field">
                                <label>Institution:</label>
                                <select class="ui dropdown" formControlName="secondary_custodian_institution" >
                                    <option value=""></option>
                                    <ng-container *ngFor="let t of institutionList">
                                        <option value="{{t.id}}">{{t.name}}</option>
                                    </ng-container>
                                </select>
                                <span class="error" *ngIf="secondary_custodian_institution?.valueOf()" style="color: red">*Secondary Custodian Institution required</span>

                            </div>
                            <div class="field">
                                <label>Division/Department:</label>
                                <input type="text"  formControlName="secondary_custodian_department">
                                <span class="error" *ngIf="secondary_custodian_department?.valueOf()" style="color: red">*Secondary Custodian Division/Department required</span>
                            </div>
                            <div class="field">
                                <label>Phone Number:</label>
                                <input type="number"  formControlName="secondary_custodian_phone_number" required appMaxLength="10" >
                                <span class="error" *ngIf="secondary_custodian_phone_number?.valueOf()" style="color: red">*Secondary Custodian Phone Number required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="field" *ngIf="this.term_click_listener == false" [formGroup]="storage_request_form_lta">
                    <label>Project Description</label>
                    <textarea  type="text" rows="2" formControlName="project_description_lta" maxlength="3500"></textarea>
                    <label>{{this.storage_request_form_lta.controls.project_description_lta.value?.length}} Characters (Min 10 Characters and Max Characters 3500)</label>
                    <span class="error" *ngIf="project_description_lta?.valueOf()" style="color: red">*More descriptive of Project Description required</span>
                </div>
                <div class="field" *ngIf="this.term_click_listener == false" [formGroup]="storage_request_form_lta">
                    <div class="ui yellow message"> <p>NB:Please ensure that you have completed a DMP and marked it as public, as part of your motivation. Please share the title of the DMP to ensure that the storage request approver can find and review it.</p></div>
                    <label>Motivation</label>
                    <textarea  type="text" rows="2" formControlName="project_motivation_lta" maxlength="3500" ></textarea>
                    <label>{{this.storage_request_form_lta.controls.project_motivation_lta.value?.length}} Characters (Min 10 Characters and Max Characters 3500)</label>
                    <span class="error" *ngIf="project_motivation_lta?.valueOf()" style="color: red">*More descriptive motivation required</span>
                </div>
                <div class="field" *ngIf="this.term_click_listener == false" [formGroup]="storage_request_form_lta">
                    <label>Data Description</label>
                    <textarea  type="text" rows="2" formControlName="data_description_lta" maxlength="3500"></textarea>
                    <label>{{this.storage_request_form_lta.controls.data_description_lta.value?.length}} Characters (Min 10 Characters and Max Characters 3500)</label>
                    <span class="error" *ngIf="data_description_lta?.valueOf()" style="color: red">*Data Decription required</span>
                </div>
            </div>
        </div>
        <br>
        <div class="form-shadow" *ngIf="this.term_click_listener == true">
            <h4 class="ui medium dividing header">Fill In Amounts</h4><br>
            <div class="two fields">
                <div class="six wide field" style="border-right: none"  [formGroup]="storage_request_form_ddt">
                    <label>Requested Amount:</label>
                    <div class="ui input">
                        <input type="number" placeholder="Amount you would like to request" formControlName="storage_size_ddt">
                    </div>
                    <span class="error" *ngIf="storage_size_ddt?.valueOf()" style="color: red">*Requested Amount required</span>
                </div>
                <div class="two wide field" style="transform: translateX(-10%);" [formGroup]="storage_request_form_ddt">
                    <label>GB/TB</label>
                    <select class="ui dropdown icon" formControlName="storage_size_metric_ddt"  style="border-left: none;" >
                        <option value="GB">Gigabyte(s)</option>
                        <option value="TB">Terabyte(s)</option>
                    </select>
                    <span class="error" *ngIf="storage_size_metric_ddt?.valueOf()" style="color: red">*GB/TB required</span>
                </div>
                <div class="six wide field" style="border-right: none" [formGroup]="storage_request_form_ddt">
                    <label>Select Months/Period for storage</label>
                    <select class="ui dropdown icon" formControlName="ddt_storage_period" >
                        <option value="1" >1</option>
                        <option value="2">2</option>
                        <option value="3" >3</option>
                        <option value="4">4</option>
                        <option value="5" >5</option>
                        <option value="6">6</option>
                        <option value="7" >7</option>
                        <option value="8">8</option>
                        <option value="9" >9</option>
                        <option value="10">10</option>
                        <option value="11" >11</option>
                        <option value="12">12</option>
                        <option value="13" >13</option>
                        <option value="14">14</option>
                        <option value="15" >15</option>
                        <option value="16">16</option>
                        <option value="17" >17</option>
                        <option value="18">18</option>
                        <option value="19" >19</option>
                        <option value="20">20</option>
                        <option value="21" >21</option>
                        <option value="22">22</option>
                        <option value="23" >23</option>
                        <option value="24">24</option>
                        <option value="25" >25</option>
                        <option value="26">26</option>
                        <option value="27" >27</option>
                        <option value="28">28</option>
                        <option value="29" >29</option>
                        <option value="30">30</option>
                        <option value="31" >31</option>
                        <option value="32">32</option>
                        <option value="33" >33</option>
                        <option value="34">34</option>
                        <option value="35" >35</option>
                        <option value="36">36</option>
                    </select>
                    <span class="error" *ngIf="ddt_storage_period?.valueOf()" style="color: red">*Storage Period in Month required</span>
                </div>

            </div>
        </div>
        <!-- ================================= LTA section 2======================================= -->
        <div class="form-shadow" *ngIf="this.term_click_listener == false" [formGroup]="storage_request_form_lta">
            <h4 class="ui medium dividing header">Fill In Amounts</h4><br>
            <div class="two fields">
                <div class="six wide field" style="border-right: none" >
                    <label>Requested Amount:</label>
                    <div class="ui input">
                        <input type="number" placeholder="Amount you would like to request" formControlName="storage_size_lta" >
                    </div>
                    <span class="error" *ngIf="storage_size_lta?.valueOf()" style="color: red">*Requested Amount required</span>
                </div>
                <div class="two wide field" style="transform: translateX(-10%);" >
                    <label>GB/TB</label>
                    <select class="ui dropdown icon" formControlName="storage_size_metric_lta"  style="border-left: none;" >
                        <option value="GB">Gigabyte(s)</option>
                        <option value="TB">Terabyte(s)</option>
                    </select>
                    <span class="error" *ngIf="storage_size_metric_lta?.valueOf()" style="color: red">*GB/TB required</span>
                </div>
                <div class="six wide field" style="border-right: none" >
                    <label>Select Years/Period for storage</label>
                    <select class="ui dropdown icon" formControlName="lta_storage_period" >
                        <option value="3" >3</option>
                        <option value="4">4</option>
                        <option value="5" >5</option>
                        <option value="6">6</option>
                        <option value="7" >7</option>
                        <option value="8">8</option>
                        <option value="9" >9</option>
                        <option value="10">10</option>
                        <option value="11" >11</option>
                        <option value="12">12</option>
                        <option value="13" >13</option>
                        <option value="14">14</option>
                        <option value="15" >15</option>
                    </select>
                    <span class="error" *ngIf="lta_storage_period?.valueOf()" style="color: red">*Storage Period in Years required</span>
                </div>

            </div>
        </div>
        <br>
        <br>
        <div class="form-shadow" *ngIf="this.term_click_listener == true">
            <h4 class="ui medium dividing header">Data Ownership</h4><br>
            <div class="content">
                <div class="two fields">
                    <div class="field" [formGroup]="storage_request_form_ddt">
                        <label>Is there intellectual property (IP) related to data?</label>
                        <select class="ui dropdown icon" formControlName="intellectual_property_ddt" (ngModelChange)="intellectualPropertyOwner()"><!--(ngModelChange)="intellectual_click_listener_fn()"-->
                            <option value="YES" >Yes, there is</option>
                            <option value="NO">No, there isn't</option>
                        </select>
                        <span class="error" *ngIf="intellectual_property_ddt?.valueOf()" style="color: red">*Intellectual Property indication required</span>
                    </div>
                    <div class="field" *ngIf="this.intellectual_click_listener == true" >
                        <label>Who owns it?</label>
                        <input type="text"  formControlName="intellectual_property_owner_ddt" >
                    </div>
                </div>
            </div>
        </div>
        <!--============================LTA Data Ownership==============================-->
        <div class="form-shadow" *ngIf="this.term_click_listener == false" >
            <h4 class="ui medium dividing header">Data Ownership</h4><br>
            <div class="content">
                <div class="two fields">
                    <div class="field" [formGroup]="storage_request_form_lta">
                        <label>Is there intellectual property (IP) related to data?</label>
                        <select class="ui dropdown icon" formControlName="intellectual_property_lta" (ngModelChange)="intellectualPropertyOwner()"><!--(ngModelChange)="intellectualPropertyOwner()"-->
                            <option value="YES" >Yes, there is</option>
                            <option value="NO">No, there isn't</option>
                        </select>
                        <span class="error" *ngIf="intellectual_property_lta?.valueOf()" style="color: red">*Intellectual Property indication required</span>
                    </div>
                    <div class="field" *ngIf="this.intellectual_click_listener == true">
                        <label>Who owns it?</label>
                        <input type="text"  formControlName="intellectual_property_owner_lta" >
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <button class="ui button compact orange" type="submit" [disabled]="this.storage_request_form_ddt.invalid && this.storage_request_form_lta.invalid" [ngClass]="{'loading disabled': is_loading}" >Submit Request</button>
        <button [routerLink]="['/home']" style="float: right" button class="ui button compact">Cancel</button>
        <br>
        <br>
        <br>
        <br>
    </form>
</div>
<app-footer></app-footer>
