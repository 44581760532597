import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Status, User } from '../model/User';

@Component({
  selector: 'app-deactivate-email',
  templateUrl: './deactivate-email.component.html',
  styleUrls: ['./deactivate-email.component.css']
})
export class DeactivateEmailComponent implements OnInit {

  username!: any
  deactivate : User | undefined

  constructor(private router:Router,private authenticationservice:AuthenticationService,private route:ActivatedRoute)
  {}
  ngOnInit(): void {
    
    this.username = this.route.snapshot.params['username']
     lastValueFrom(this.authenticationservice.deactivate_email(this.username)).then((user : User) => {
      this.deactivate = user;
      if (this.deactivate.status !== Status.PENDING) {
        this.router.navigate(['emailconfirmed']).then();
       }

     }
  )};

}
