import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { UpdateRoleRequest } from 'src/app/model/Updaterolerequest';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/model/UserRole';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserroleService {

  constructor(private httpClient : HttpClient) {

  }
 updateUserRole(id:number, updateRoleRequest:UpdateRoleRequest): Observable<Object>{
   return this.httpClient.put(environment.base+"auth/user/updaterole/"+id, updateRoleRequest);
 }

 getAllUserRoles(): Observable<Role[]> {
   return this.httpClient.get<Role[]>(environment.base + 'auth/retrieveroles');
}
 
}
