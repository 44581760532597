import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetHelpRequest } from 'src/app/model/gethelprequest';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GetHelp } from 'src/app/model/gethelp';
import { UpdateGetHelpRequest } from 'src/app/model/updategethelprequest';
@Injectable({
  providedIn: 'root'
})
export class GothelpService {

  constructor(private httpClient : HttpClient) {

  }
  createGetHelp(getHelpRequest: GetHelpRequest): Observable<Object> {
   return this.httpClient.post(environment.base+"gothelp/issue", getHelpRequest);
   
 }
  getAllGotHelpList(): Observable<GetHelp[]> {
   return this.httpClient.get<GetHelp[]>(environment.base+"gothelp/issue/list");
 }
 getGotHelpById(id: number): Observable<GetHelp>{
   return this.httpClient.get<GetHelp>(environment.base+"gothelp/get/issue/"+id);
 }

 updateGotHelp(id:number, updateGotHelpRequest:GetHelp): Observable<Object>{
   return this.httpClient.put(environment.base+"gothelp/update/issue/"+id, updateGotHelpRequest);
 }
 deleteHelp(id:number): Observable<Object>{
  return this.httpClient.delete(environment.base+"gothelp/delete/issue/"+id);
}
}
