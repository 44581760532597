export class GetHelp {
    id!:number;
    requestEmail?: string;
    requestName?: string;
    requestSurname?: string;
    replyEmail?: string;
    issue!: string;
    reply?: string;
    createdAt?:Date;
}
