<!--<app-index-top-menu></app-index-top-menu>-->

<div style="min-height: 100vh; padding-top: 15vh; background-color: rgba(0,0,0,0.1)">
    <div class="ui container" style="margin-bottom: 10vh">
        <div class="ui ordered steps fluid mobile tablet stackable">
            <div class="step" [ngClass]="{'completed': step>1 }" >
                <div class="content">
                    <div class="title">Email</div>
                    <div class="description">Send OTP</div>
                </div>
            </div>
            <div class=" step" [ngClass]="{'completed': step>2}">
                <div class="content">
                    <div class="title">OTP</div>
                    <div class="description">Enter OTP</div>
                </div>
            </div>
            <div class="step">
                <div class="content">
                    <div class="title">Password</div>
                    <div class="description">New Password</div>
                </div>
            </div>
        </div>
    </div>

    <div class="ui card" *ngIf="step==1" style="margin: auto; min-width: 450px">
        <div class="content">
            <div class="header">Email</div>
        </div>
        <div class="content">
            <dev class="ui form" [formGroup]="email_form">
                <div class="field" [class.error]="this.email_form.get('username')?.invalid && form_check">
                    <label>Email</label>
                    <input type="text" formControlName="username" autofocus>
                </div>

                <button [ngClass]="{'loading disabled': is_loading}" (click)="openThirdPartyWarningModal()" type="button" class="ui button compact orange">Verify Email</button>
                <button [routerLink]="['/']" style="float: right" button class="ui button compact">Cancel</button>
            </dev>
        </div>
    </div>

    <div class="ui card" *ngIf="step==2" style="margin: auto; min-width: 450px">
        <div class="content">
            <div class="header">One-Time PIN</div>
        </div>
        <div class="content">
            <form class="ui form" [formGroup]="otp_form">
                <div class="field" [class.error]="this.otp_form.get('otp')?.invalid && form_check">
                    <label>OTP</label>
                    <input type="text" formControlName="otp" autofocus>
                </div>

                <button [ngClass]="{'loading disabled': is_loading}" (click)="otp_form_submit()" type="button" class="ui button compact orange">Verify OTP</button>
                <button [routerLink]="['/']" style="float: right" button class="ui button compact">Cancel</button>
            </form>
        </div>
    </div>

    <div class="ui card" *ngIf="step==3"  style="margin: auto; min-width: 450px">
        <div class="content">
            <div class="header">New Password</div>
        </div>
        <div class="content">
            <form class="ui form" [formGroup]="password_form">

                <div class="field" [class.error]="(myPasswordStrength<100) && form_check">
                    <label>Password</label>

                    <div class="ui action input">
                        <input  [type]="isPasswordVisible ? 'password' : 'text'" formControlName="password" (keyup)="password_check()" autofocus>
                        <button class="ui icon button" type="button" (click)="show_hide_password()">
                            <i class="eye slash outline icon"></i>
                        </button>
                    </div>

                </div>

                <div class="ui tiny progress indicating" id="password_strength_count">
                    <div class="bar"></div>
                    <div class="label" style="text-align: left" >Password Strength: {{password_check()}}</div>
                </div>


                <div class="field" [class.error]="this.password_form.get('password')?.value != this.password_form.get('repeat_password')?.value && form_check">
                    <label>Repeat Password</label>

                    <div class="ui input">
                        <input  type="password" formControlName="repeat_password">
                    </div>

                </div>

                <div class="item" *ngIf="this.password_form.get('repeat_password')?.valid" >
                    <a class="ui red empty circular label" [ngClass]="{'green': isPasswordEqual}"></a>
                    Passwords Matches
                </div>

                <button [ngClass]="{'loading disabled': is_loading}" (click)="password_form_submit()" type="button" class="ui button compact orange">Update Password</button>
                <button [routerLink]="['/']" style="float: right" button class="ui button compact">Cancel</button>
                <div class="ui divided list large">

                    <div class="item">
                        <a class="ui red empty circular label" [ngClass]="{'green': includeLowerCase && includeUpperCase}" ></a>
                        At least one lowercase & one uppercase character
                    </div>
                    <div class="item">
                        <a class="ui red empty circular label" [ngClass]="{'green': includeNumber}" ></a>
                        At least one number (0-9)
                    </div>
                    <div class="item">
                        <a class="ui red empty circular label" [ngClass]="{'green': includeSymbol}"></a>
                        At least one Special character (!@#$%^&*)
                    </div>
                    <div class="item">
                        <a class="ui red empty circular label" [ngClass]="{'green': hasEightCharacters}"></a>
                        At least 8 characters
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>



<div class="ui modal small thirdPartyWarningModal" id="thirdPartyWarningModal">
    <div class="header">Third Party Warning</div>
    <div class="content scrolling" style="font-size: larger;font-weight: bold">

        <img *ngIf="currentUser?.orcidRegistered" src="../../../assets/images/ORCID_logo_with_tagline.png" alt="ORCID logo" height="60" style="margin: auto">
        <img *ngIf="currentUser?.safireRegistered" src="../../../assets/images/safire-logo.png" alt="ORCID logo" height="60" style="margin: auto">

        <span *ngIf="currentUser?.orcidRegistered">
            <p>You are currently registered with ORCID. If you reset your password, you will switch to manual login.</p>
            <p>Do you want to continue?</p>
        </span>
        <span *ngIf="currentUser?.safireRegistered">
            <p>You are currently registered with SAFIRE. If you reset your password, you will switch to manual login.</p>
            <p>Do you want to continue?</p>
        </span>

    </div>
    <div class="actions">
        <div class="ui button grey" (click)="goToIndex()">No</div>
        <div class="ui button orange" (click)="emailFormSubmit()">Yes</div>
    </div>
</div>


<app-footer></app-footer>
