<!DOCTYPE html>
<html>
<head>

<meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body>
  <img src="../../../assets/images/DIRISA_logo_RGB.png" alt="Girl in a jacket" height="70" ><br/><br/>
  <div class="ui green message">
    <div class="header">
    </div>
    <div> Thank you! <br>{{ message }}</div>
  </div>
</body>
</html>