import { Injectable } from '@angular/core';
import {AuthConfig, OAuthService} from "angular-oauth2-oidc";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {User} from "../../model/User";
import {error} from "jquery";
import Swal from "sweetalert2";


const oAuthConfig: AuthConfig = {
    issuer:environment.safireIssuer,
    strictDiscoveryDocumentValidation: false,
    redirectUri: window.location.origin,
    clientId: environment.safireClientId,
    scope: 'openid profile email',
    // @ts-ignore
    kc_idp_hint:'saml'
}
@Injectable({
  providedIn: 'root'
})
export class SafireService {
    constructor(private oAuthService:OAuthService, private httpClient:HttpClient, private router:Router) {}

    startFlow(){

        this.oAuthService.configure(oAuthConfig)
        this.oAuthService.loadDiscoveryDocument().then((data) => {
            this.oAuthService.tryLoginImplicitFlow().then((data2) => {
                this.oAuthService.initCodeFlow()

                /*if (!this.oAuthService.hasValidAccessToken()) {
                      this.oAuthService.initCodeFlow()
                } else {
                    this.oAuthService.loadUserProfile().then((data:any)=>{
                          //Capture data if receive response
                    })
                  }*/
            }).catch((error)=>{
                Swal.fire({
                    title: "SAFIRE",
                    text: "SAML token has expired, click ok to load again.",
                    icon: "warning",
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = environment.entryUrl;
                    }
                });
            })
        })
    }

    getUser(email: any) {
        return this.httpClient.post<User[]>(environment.base+'safire/getUser',{"email":email})
    }

    overrideUserWithSafire(email: string | null, password: string | null) {
        return this.httpClient.post<User>(environment.base+'safire/overrideUserWithSafire',{"email":email, "password":password})
    }

    registerWithSafire(data:any) {
        return this.httpClient.post<User>(environment.base+'safire/registerWithSafire',data)
    }

}
