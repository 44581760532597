<app-top-navigation></app-top-navigation>
<div style="min-height: 100vh; padding-top: 15vh; background-color: rgba(0,0,0,0.1)">
    <div class="header" style="margin-top: 80px; text-align: center" *ngIf="!current_req?.length">
    <h5>You have no storage request </h5>
    </div>
    <div class="header" style="text-align: center" *ngIf="current_req?.length">
        <h4>List Of Your Current Storage Requests </h4>
        </div><br/>
    <div class="ui container" style="margin-bottom: 10vh"*ngFor="let a of current_req; let i = index" >
            <div class="ui ordered steps fluid mobile tablet stackable" *ngIf="a.storageRequestStatus == 'PENDING'">
                <div class="content">
                    <br/>
                    <div class="description" *ngIf="a.storageTerm == 'LONG'"><label><strong>&nbsp;&nbsp;&nbsp;Storage Type:<br/>&nbsp;&nbsp;&nbsp;Long Term Archive (LTA) ({{a.storageSize}}{{a.storageSizeMetric}})&nbsp;&nbsp;&nbsp; </strong></label></div>
                    <div class="description" *ngIf="a.storageTerm == 'SHORT'"><label><strong>&nbsp;&nbsp;&nbsp;Storage Type:<br/>&nbsp;&nbsp;&nbsp;Data Deposit Tool (DDT) ({{a.storageSize}}{{a.storageSizeMetric}})&nbsp;&nbsp;&nbsp;</strong></label></div>
                </div>
                <div class=" step">
                    <div class="content">
                        <div class="title">Submitted &#9989;</div>
                        <div class="description">Application Submitted</div>
                    </div>
                </div>
                <div class="step">
                    <div class="content">
                        <div class="title">Pending  &#8987;</div>
                        <div class="description">Application Pending For Approval</div>
                    </div>
                </div>
                <div class="step">
                    <div class="content">
                        <div class="title">Implemented &#8987;</div>
                        <div class="description">Application Approved Awaiting For Implementation</div>
                    </div>
                </div>
                </div>
                <div class="ui ordered steps fluid mobile tablet stackable" *ngIf="a.storageRequestStatus == 'REJECTED'">
                    <div class="content">
                        <br/>
                        <div class="description" *ngIf="a.storageTerm == 'LONG'"><label><strong>&nbsp;&nbsp;&nbsp;Storage Type:<br/>&nbsp;&nbsp;&nbsp;Long Term Archive (LTA) ({{a.storageSize}}{{a.storageSizeMetric}})&nbsp;&nbsp;&nbsp; </strong></label></div>
                        <div class="description" *ngIf="a.storageTerm == 'SHORT'"><label><strong>&nbsp;&nbsp;&nbsp;Storage Type:<br/>&nbsp;&nbsp;&nbsp;Data Deposit Tool (DDT) ({{a.storageSize}}{{a.storageSizeMetric}})&nbsp;&nbsp;&nbsp;</strong></label></div>
                    </div>
                    <div class=" step">
                        <div class="content">
                            <div class="title">Submitted &#9989;</div>
                            <div class="description">Application Submitted</div>
                        </div>
                    </div>
                    <div class=" step">
                        <div class="content">
                            <div class="title">Rejected &#10060;</div>
                            <div class="description">Application Was Unsuccessfully</div>
                        </div>
                    </div>
                    </div>
                    <div class="ui ordered steps fluid mobile tablet stackable" *ngIf="a.storageRequestStatus == 'APPROVED'">
                        <div class="content">
                            <br/>
                            <div class="description" *ngIf="a.storageTerm == 'LONG'"><label><strong>&nbsp;&nbsp;&nbsp;Storage Type:<br/>&nbsp;&nbsp;&nbsp;Long Term Archive (LTA) ({{a.storageSize}}{{a.storageSizeMetric}})&nbsp;&nbsp;&nbsp; </strong></label></div>
                            <div class="description" *ngIf="a.storageTerm == 'SHORT'"><label><strong>&nbsp;&nbsp;&nbsp;Storage Type:<br/>&nbsp;&nbsp;&nbsp;Data Deposit Tool (DDT) ({{a.storageSize}}{{a.storageSizeMetric}})&nbsp;&nbsp;&nbsp;</strong></label></div>
                        </div>
                        <div class=" step">
                            <div class="content">
                                <div class="title">Submitted &#9989;</div>
                                <div class="description">Application Submitted</div>
                            </div>
                        </div>
                        <div class=" step">
                            <div class="content">
                                <div class="title">Approved &#9989;</div>
                                <div class="description">Application Was Successfully</div>
                            </div>
                        </div>
                        <div class="step">
                            <div class="content">
                                <div class="title">Implemented &#8987;</div>
                                <div class="description">Pending For Implementation</div>
                            </div>
                        </div>
                        </div>
                        <div class="ui ordered steps fluid mobile tablet stackable" *ngIf="a.storageRequestStatus == 'IMPLEMENTED'">
                                <div class="content">
                                    <br/>
                                    <div class="description" *ngIf="a.storageTerm == 'LONG'"><label><strong>&nbsp;&nbsp;&nbsp;Storage Type:<br/>&nbsp;&nbsp;&nbsp;Long Term Archive (LTA) ({{a.storageSize}}{{a.storageSizeMetric}})&nbsp;&nbsp;&nbsp; </strong></label></div>
                                    <div class="description" *ngIf="a.storageTerm == 'SHORT'"><label><strong>&nbsp;&nbsp;&nbsp;Storage Type:<br/>&nbsp;&nbsp;&nbsp;Data Deposit Tool (DDT) ({{a.storageSize}}{{a.storageSizeMetric}}) &nbsp;&nbsp;&nbsp;</strong></label></div>
                                </div>
                            <div class=" step">
                                <div class="content">
                                    <div class="title">Submitted &#9989;</div>
                                    <div class="description">Application Submitted</div>
                                </div>
                            </div>
                            <div class=" step">
                                <div class="content">
                                    <div class="title">Approved &#9989;</div>
                                    <div class="description">Application Was Successfully</div>
                                </div>
                            </div>
                            <div class=" step">
                                <div class="content">
                                    <div class="title">Implemented&#9989;</div>
                                    <div class="description">Implementation Was Successfully</div>
                                </div>
                            </div>
                            </div>  
        </div>
</div>

<app-footer></app-footer>
        
   