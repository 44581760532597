import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import {AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import { lastValueFrom} from "rxjs";
import { passwordStrength } from 'check-password-strength'
import { NgxCaptureService } from "ngx-capture";
import { Institution } from "../model/Institution";
import { AuthenticationService } from "../services/auth/authentication.service";
import { InstitutionService } from "../services/institution/institution.service";
import { SafireService } from "../services/safire/safire.service";
import { OrcidUser } from "../model/OrcidUser";
import { AlertService } from "../services/alert/alert.service";
import { environment } from "../../environments/environment";

declare var $: any
declare var toastr: any

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    protected readonly environment = environment;

    @ViewChild('screen', { static: true }) screen: any;

    is_loading: boolean = false
    formCheck: boolean = false
    institutionList: Institution[] = []
    myPasswordStrength: number = 0;

    isPasswordVisible: boolean = false;
    private is_recapture: boolean = false;

    public step: number = 1;

    includeLowerCase: boolean = false
    includeUpperCase: boolean = false
    includeSymbol: boolean = false
    includeNumber: boolean = false
    hasEightCharacters: boolean = false
    isTermConditionCheckedError: boolean = false;
    isStoragePrivacyCheckedError: boolean = false;
    isStoragePolicyCheckedError: boolean = false;
    isRecaptureCheckedError: boolean = false;
    isCheckedError: boolean = false;
    private terms_and_conditions_accepted: boolean = false
    private storage_privacy_accepted: boolean = false
    private storage_policy_accepted: boolean = false
    orcidCode: string | undefined;
    orcidRegistrationStep: number = 0
    orcidUser: OrcidUser | undefined
    isOrcidMetadataLoading: boolean = false
    isOrcidRegistrationLoading: boolean = false
    orcidCompletionFormCheck: boolean = false
    isOrcidRegistration: boolean = false
    leadingAndTrailingSpaces: boolean = false

    constructor(
        private authenticationService: AuthenticationService,
        private institutionService: InstitutionService,
        private router: Router,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute
    ) { }



    ngOnInit(): void {
        lastValueFrom(this.institutionService.read_all()).then((data) => {
            this.institutionList = data


        }).catch(error => { }).finally(() => {
            $('#institution').dropdown({ direction: 'upward' });
            $('#orcidInstitution').dropdown({ direction: 'upward' });
        })


        this.activatedRoute.queryParams.subscribe(params => {

            // @ts-ignore
            this.orcidCode = params.code;
            this.isOrcidRegistration = true

            if (this.orcidCode != undefined) {
                $('#orcid_initiation_modal').modal('show')
            }

        });

        $('.ui.dropdown')
        .dropdown()
        ;
    }

    register_form = this.formBuilder.group({
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        username: ['', [Validators.required, Validators.email]],
        institution: ['', [Validators.required]],
        password: ['', [Validators.required]],
        motivation: ['', [Validators.required,Validators.minLength(10), Validators.max(200), noOnlySpacesValidator()]]
    });

    terms_and_conditions_from = this.formBuilder.group({
        // motivation: ['', [Validators.required, Validators.max(200)]]
    });


    show_terms_and_conditions() {
        this.formCheck = true;
        if (this.register_form.invalid) {
            toastr.options = {
                "progressBar": true,
                "positionClass": "toast-top-right"
            }
        } else {

            if (this.register_form.valid && this.myPasswordStrength >= 75) {
                $('#tc_modal').modal('show');
            }

            $('#terms_and_condition .checkbox ').checkbox({
                onChecked: () => {
                    this.terms_and_conditions_accepted = true;
                    this.isTermConditionCheckedError = false;
                },
                onUnchecked: () => {
                    this.terms_and_conditions_accepted = false;
                    this.isTermConditionCheckedError = true;
                }
            });
            $('#storage_privacy .checkbox ').checkbox({
                onChecked: () => {
                    this.storage_privacy_accepted = true;
                    this.isStoragePrivacyCheckedError = false;
                },
                onUnchecked: () => {
                    this.storage_privacy_accepted = false;
                    this.isStoragePrivacyCheckedError = true;
                }
            });
            $('#storage_policy .checkbox ').checkbox({

                onChecked: () => {
                    this.storage_policy_accepted = true
                    this.isStoragePolicyCheckedError = false;
                },
                onUnchecked: () => {
                    this.storage_policy_accepted = false;
                    this.isStoragePolicyCheckedError = true;
                }
            });
        }
    }


    register_form_submit() {
        if (!this.is_recapture) {
            this.isRecaptureCheckedError = true;
        }
        if (!this.storage_policy_accepted) {
            this.isStoragePolicyCheckedError = true;
        }
        if (!this.storage_privacy_accepted) {
            this.isStoragePrivacyCheckedError = true;
        }
        if (!this.terms_and_conditions_accepted) {
            this.isTermConditionCheckedError = true;
        }
        if (this.is_recapture && this.terms_and_conditions_from &&
            this.terms_and_conditions_accepted && this.storage_privacy_accepted && this.storage_policy_accepted && !this.leadingAndTrailingSpaces) {
            this.is_loading = true
            this.formCheck = false
            this.isCheckedError = false;
            this.isTermConditionCheckedError = false;
            this.isStoragePrivacyCheckedError = false;
            this.isStoragePolicyCheckedError = false;
            this.isRecaptureCheckedError = false;
            lastValueFrom(this.authenticationService.register({
                "username": (this.register_form.get('username')?.value)?.toLowerCase(),
                "firstName": this.register_form.get('first_name')?.value,
                "lastName": this.register_form.get('last_name')?.value,
                "motivation": this.register_form.get('motivation')?.value,
                // @ts-ignore
                "institutionId": parseInt(this.register_form.get('institution')?.value),
                "password": this.register_form.get('password')?.value,
                "termsAndConditions": this.terms_and_conditions_accepted.valueOf(),
                "storagePrivacy": this.storage_privacy_accepted.valueOf(),
                "storagePolicy": this.storage_policy_accepted.valueOf()
            })).then(data => {
                toastr.options = {
                    "progressBar": true,
                    "positionClass": "toast-top-right"
                }
                toastr["success"]("success", "You have successfully registered")

                $('#tc_modal').modal('hide');
                this.router.navigate(['']).then();

            }).catch(error => {
                if (error.status == 409) {
                    $('#tc_modal').modal('hide');
                    this.router.navigate(['']).then(() => {
                        toastr.options = {
                            "progressBar": true,
                            "positionClass": "toast-top-right"
                        }

                        toastr["warning"]("Email already registered", "Please login or reset password")
                        $('#tc_modal').modal('hide');
                    });

                } else {
                    toastr.options = {
                        "progressBar": true,
                        "positionClass": "toast-top-right"
                    }
                    toastr["error"]("Unable to register", "Please contact admin")
                    $('#tc_modal').modal('hide');
                }

            }).finally(() => {
                this.is_loading = false;
                $('#tc_modal').modal('hide');
            })
        } else {
            this.isCheckedError = true;
        }

    }


    registerWithOrcidTermsAndConditions() {
        $('#tc_modal').modal('show')
    }

    password_check() {
        // @ts-ignore
        let password: string = this.register_form.controls.password.value
        this.myPasswordStrength = (passwordStrength(password).id + 1) * 25

        if (password.length < 2) {
            this.myPasswordStrength = 0
        }
        $('#password_strength_count').progress({
            percent: this.myPasswordStrength
        });

        if (passwordStrength(password).contains.includes('lowercase')) {
            this.includeLowerCase = true
        } else {
            this.includeLowerCase = false
        }

        if (passwordStrength(password).contains.includes('uppercase')) {
            this.includeUpperCase = true
        } else {
            this.includeUpperCase = false
        }

        if (passwordStrength(password).contains.includes('symbol')) {
            this.includeSymbol = true
        } else {
            this.includeSymbol = false
        }

        if (passwordStrength(password).contains.includes('number')) {
            this.includeNumber = true
        } else {
            this.includeNumber = false
        }

        if (passwordStrength(password).length > 7) {
            this.hasEightCharacters = true
        } else {
            this.hasEightCharacters = false
        }

        return passwordStrength(password).value
    }

    show_hide_password() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    resolved(captchaResponse: string) {
        this.is_recapture = true;
        this.isRecaptureCheckedError = false;
    }

    close_tc_modal() {
        $('#tc_modal').modal('hide');
    }

    go_to_login() {
        this.router.navigate([""]).then()
    }

    trimSpaces(event: Event){
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;

        if (inputValue.startsWith(" ") || inputValue.endsWith(" ")){
            this.leadingAndTrailingSpaces = true
        }else {
            this.leadingAndTrailingSpaces = false
        }
    }
}

export function noOnlySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const isValid = control.value && control.value.trim().length > 0;
        return isValid ? null : { 'onlySpaces': true };
    };
}

