import { Injectable } from '@angular/core';
import {HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from "@angular/router";
import {catchError, lastValueFrom, switchMap, throwError} from "rxjs";
import {AuthenticationService} from "../auth/authentication.service";
@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements  HttpInterceptor {

    constructor(private router: Router, private authenticationService:AuthenticationService, ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let tokenizedRequest = null

        tokenizedRequest = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        return next.handle(tokenizedRequest).pipe(catchError(err => {
            return next.handle(req)
        }));
    }

    handleRefreshToken(req: HttpRequest<any>, next: HttpHandler){
        this.authenticationService.refresh_token().pipe(
            switchMap((data:any)=>{
                return next.handle(req)
            }),

            catchError(err => {
                this.authenticationService.log_out()
                // logout
                return throwError(err)
            })
        );
    }
}
