import { Institution } from "./Institution";
import { User } from "./User";

export class Administrator{
    adminId!:number;
    userId!: number;
    type!: string;
    email!: string;
    username!:string;
    status!: string;
    firstName!: string;
    active!: boolean;
    lastName!:string;
    createdAt!: Date;
    institution!: Institution;
        
}