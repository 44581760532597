import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {RejectionReason} from "../../model/RegectionReason";

@Injectable({
  providedIn: 'root'
})
export class RejectionService {

    constructor(private httpClient: HttpClient) {
    }

    getListOfUserRejection() {
        return this.httpClient.get<RejectionReason[]>(environment.base + 'getallreason');
    }

    getListOfStorageRejection() {
        return this.httpClient.get<RejectionReason[]>(environment.base + 'storage/getreason');
    }
    createReasons(data: any) {
        return this.httpClient.post<RejectionReason>(environment.base + 'createreason', data)
    }

    getReasonsById(id: number) {
        return this.httpClient.get(environment.base + 'getreason/{id}')

    }

    updateReasons(id: number, data: any) {
        return this.httpClient.put(environment.base + 'updatebyid/' + id, data)

    }

    deleteReason(id: number) {

        return this.httpClient.get(environment.base + 'deletereasonbyid/' + id)

    }
}
