import { Directive, HostListener, Input } from "@angular/core";

@Directive({
    selector: "[appMaxLength]"
})
export class MaxLengthDirective {
    // @ts-ignore
    @Input() appMaxLength: string ;
    constructor() {}

    @HostListener("keydown", ["$event"]) onKeydown(event: { target: { value: any; }; preventDefault: () => void; stopPropagation: () => void; }) {
        const value = event.target.value;
        const maxLength = parseInt(this.appMaxLength);
        if (value.length > maxLength -1) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
