import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationModel } from 'src/app/model/Notification';
import { NotificationRequest } from 'src/app/model/NotificationsRequest';
import { PublishNotification } from 'src/app/model/PublishNotificationRequest';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpClient: HttpClient) { }

    saveNotification(notificationRequest:NotificationRequest):Observable<Object>{
      return this.httpClient.post(environment.base+"notification/save",notificationRequest);
    }
    updateNotification(id:number,updateNotificationRequest:NotificationRequest):Observable<Object>{
      return this.httpClient.put(environment.base+"notification/update/"+id,updateNotificationRequest);
    }
    getAllNotifications():Observable<NotificationModel[]>{
      return this.httpClient.get<NotificationModel[]>(environment.base+"notification/list");
      }
      getNotification(id:number):Observable<NotificationModel>{
        return this.httpClient.get<NotificationModel>(environment.base+"notification/get/"+id);
      }
      publishNotification(id:number,publishNotification:PublishNotification):Observable<Object>{
        return this.httpClient.put(environment.base+"notification/pulish/"+id,publishNotification);
      }
      unsubscriptNotification(username:string,unsubscribeUser:boolean):Observable<Object>{
        return this.httpClient.put(environment.base+"notification/unsubscribe/"+username,unsubscribeUser);
      }
      deleteNotification(id:number): Observable<Object>{
        return this.httpClient.delete(environment.base+"notification/delete/"+id);
    }
  }
