import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {User} from "../../model/User";
import {OrcidUser} from "../../model/OrcidUser";


@Injectable({
  providedIn: 'root'
})
export class OrcidService {
    constructor(private httpClient:HttpClient, private router:Router) {}

    getUser(email: any) {
        return this.httpClient.post<User[]>(environment.base+'safire/getUser',{"email":email})
    }

    overrideUserWithOrcid(email: string | null, password: string | null) {
        return this.httpClient.post<User>(environment.base+'orcid/overrideUserWithOrcid',{"email":email, "password":password})
    }

    registerWithOrcid(data:any) {
        return this.httpClient.post<User>(environment.base+'orcid/registerWithOrcid',data)
    }

    getOrcidMetadata(orcidCode: string | undefined | null) {
        let s = "https://"+environment.orcidEntryUrl+"/hs/integration/orcid_metadata/"+orcidCode+"/"+environment.orcidEntryUrl
        return this.httpClient.get(s)
    }
}
