import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from "@angular/router";
import {User} from "../../model/User";
import {Role} from "../../model/UserRole";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService{

    constructor(private httpClient:HttpClient, private router:Router) {
    }
    activate_email(username: any) {
        return this.httpClient.get<User>(environment.base+'auth/activation/'+username)
    }

    deactivate_email(username: any) {
        return this.httpClient.get<User>(environment.base+'auth/decline/'+username)
    }

    logIn(data: any) {
        return this.httpClient.post(environment.base+'auth/login', data)
    }

    register(data: any) {
        return this.httpClient.post<User>(environment.base + 'auth/save', data);
    }

    create_OTP(data: any) {
        return this.httpClient.post<User>(environment.base + 'auth/createotp', data);
    }

    logged_in() {
        let token = localStorage.getItem("access_token")
        if (token != null) {
            return true
        } else {
            return false
        }
    }

    verify_OTP(data: any) {
        return this.httpClient.post(environment.base + 'auth/verifyotp', data);
    }

    getUserProfile() {
        return this.httpClient.get<User>(environment.base + 'auth/user');
    }

    update_user(data: any){
        return this.httpClient.put<User>(environment.base + 'auth/user', data);
    }

    update_password(data: any){
        return this.httpClient.put<User>(environment.base + 'auth/password', data);
    }

    get_all_users() {
        return this.httpClient.get<User[]>(environment.base + 'admin/user/users');
    }
    get_deactivated_users() {
        return this.httpClient.get<User[]>(environment.base + 'admin/user/deleted');
    }

    set_user_status(data: any) {
        return this.httpClient.put<User>(environment.base + 'admin/user/status', data);
    }

    refresh_token() {
        return this.httpClient.get<User>(environment.base + 'auth/token/refresh');
    }

    log_out() {
        localStorage.clear()
    }

    deactivate_user(username: string) {
        return this.httpClient.put<User>(environment.base + 'admin/user/deactivate', {"username":username});
    }

    get_users_count() {
        return this.httpClient.get<number>(environment.base + 'admin/user/count');
    }

    put_restore_users(username: string) {
        return this.httpClient.put<User>(environment.base + 'admin/user/activate', {"username":username});
    }

    getOrcidData(code: any) {
        return this.httpClient.post<User>(environment.base + 'auth/user/orciddata', code);
    }

    accept_storage_terms_and_conditions(username:string) {
        return this.httpClient.put<User>(environment.base + 'auth/user/acceptstoragetermsandconditions', {"username":username});
    }

    change_user_role(data: any){
        return this.httpClient.put<User>(environment.base + 'auth/user/updaterole', data);
    }

    get_all_roles(){
        return this.httpClient.get<Role[]>(environment.base + 'auth/retrieveroles');
    }

    authenticate_with_safire(data: any) {
        return this.httpClient.post<any>(environment.base + 'safire/register', data );
    }
    softDeleteUser(username: string): Observable<User> {
        return this.httpClient.get<User>(environment.base +'auth/softdelete/'+username);
      }
    confirmDelete(uuid: string): Observable<User> {
        return this.httpClient.get<User>(environment.base +'auth/confirmdelete/'+uuid);
      }

    declineDelete(uuid: string): Observable<User> {
        return this.httpClient.get<User>(environment.base +'auth/declinedelete/'+uuid);
      }

    hardDeleteUser(username: string):Observable<User>{
        return this.httpClient.delete<User>(environment.base + 'auth/harddelete/'+username)
    }
}
