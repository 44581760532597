<app-top-navigation></app-top-navigation><br/><br/>

<div class="header" style="margin-top: 80px; text-align: center" *ngIf="!getHelp?.length">
    <h5>No New user help request</h5>
    </div>
<div class="ui container" style="margin-top: 100px" *ngIf="getHelp?.length">
    <h4 class="text-center">List Of User Issues</h4>
    <table class="ui single line table large selectable" style="border-collapse: collapse;" >
        <thead>
            <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th >Issue</th>
                <th>Created Date</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let help of getHelp">
                <td> {{ help.requestName }} </td>
                <td> {{ help.requestSurname }} </td>
                <td> {{ help.requestEmail }} </td>
                <td style="white-space: normal;"> {{ help.issue }} </td>
                <td> {{ help.createdAt | date:'d-MMMM-y HH:mm' }} </td>
                <td class="collapsing">
                    <a class="ui yellow empty circular label" title="Pending" *ngIf="help.reply == null">PENDING</a>
                    <a class="ui green empty circular label" title="Replied" *ngIf="help.reply != null">REPLIED</a>
                </td>
                <td>
                    <button (click)="updateGetHelp(help.id)" class="ui orange button"
                        *ngIf="help.reply == null">Reply</button>
                    <button (click)="updateGetHelp(help.id)" class="ui orange button" *ngIf="help.reply != null"
                        disabled>Reply</button>
                   <!--<button (click)="deleteGetHelp(help.id)" class="ui red button">Delete</button>--> 
                    <button (click)="confirmDelete(help.id)" class="ui red button">Delete</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="ui modal small" id="confirmationDeletion">
    <div class="header">Delete Confirmation</div>
    <div class="content">
        <p>Are you sure you want to delete this item?</p>
        <div class="actions">
            <button class="ui green button compact"  (click)="deleteGetHelp(idNum)" >Yes</button>
            <button class="ui red button compact" style="float: left" (click)="notSureDelete()">No</button>
                
        </div>
    </div>
</div>