<app-top-navigation></app-top-navigation>

<div class="ui container" style="margin-top: 100px;">
    <h4 class="text-center">Failed Email List</h4>  

    <div class="table-container" style="overflow-x: auto;">
        <table class="ui celled padded table">
            <thead>
                <tr>
                    <th>Email Subject</th>
                    <th>Message Body</th>
                    <th>Assign</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let failed of failedEmails; let i = index">
                    <td>{{ failed.subject }}</td>
                    <td style="white-space: normal;">{{ failed.messageBody }}</td>
                    <td>{{ failed.failedEmail }}</td>
                    <td>
                        <button class="ui button red" (click)="ResendEmail(failed, i)" [disabled]="failed.failedEmail">Send</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
