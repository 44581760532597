import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {Institution} from "../../model/Institution";

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

    constructor(private httpClient:HttpClient, private router:Router) {
    }

    read_all() {
       return this.httpClient.get<Institution[]>(environment.base+'institution/get')
    }


}
