import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Validators, FormBuilder} from "@angular/forms";
import {lastValueFrom} from "rxjs";
import {Status, User} from "../model/User";
import {AuthenticationService} from "../services/auth/authentication.service";
import {environment} from "../../environments/environment";
import {AlertService} from "../services/alert/alert.service";
import {SafireService} from "../services/safire/safire.service";
import decodeToken from "@leteu/jwt-decoder";
import {InstitutionService} from "../services/institution/institution.service";
import {Institution} from "../model/Institution";
import {SafireUser} from "../model/SafireUser";

declare var  $:any
declare var  toastr:any

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    protected readonly environment = environment;


    isLoading: boolean = false
    formCheck: boolean = false
    isLoginUnsuccessful: boolean = false

    currentUser: User | undefined
    isPasswordVisible: boolean = true
    institutionList: Institution[] = []
    isRecapture = true;
    isRecaptureCheckedError = false;


    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private formBuilder: FormBuilder,
                private activatedRoute:ActivatedRoute,
                private safireService:SafireService
    ) {
        localStorage.clear();
        sessionStorage.clear();
        this.clearAllCookies();
    }

    ngOnInit(): void {

        //ORCID login
        this.activatedRoute.queryParams.subscribe(params => {

            if(params['code'] != undefined) {
                this.router.navigate(["orcid/"+params['code']])
            }
        });

        //SAFIRE login
        let url = this.router.url
        try {
            let accessToken = (url.split("=")[4]).split('&')[0]
            this.router.navigate(['safire/'+accessToken])

        }catch (e) {}
    }

    log_in_form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['',[ Validators.required, Validators.minLength(8)]]
    });

    onBlurEmail() {
        const emailControl = this.log_in_form.get('email');
        // @ts-ignore
        emailControl.setValue(emailControl.value.trim());
    }

    show_hide_password() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    log_in_form_submit() {

        this.formCheck = true
        this.isLoginUnsuccessful = false
        this.currentUser = undefined

        if (this.log_in_form.valid) {

            localStorage.clear();

            this.isLoading = true
            this.formCheck = false

            lastValueFrom(this.safireService.getUser(this.log_in_form.controls.email.getRawValue())).then((users:User[])=>{
                if(users.length == 0) {
                    this.isLoginUnsuccessful = true
                }else {
                    this.currentUser = users[0]
                    if(this.currentUser.orcidRegistered || this.currentUser.safireRegistered) {
                        $('#thirdPartyWarningModal').modal('show')
                    }
                }

            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{})

            lastValueFrom(this.authenticationService.logIn({
                "username": (this.log_in_form.get('email')?.value)?.toLowerCase(),
                "password": this.log_in_form.get('password')?.value
            })).then(data => {

                // @ts-ignore
                if (this.currentUser.status == Status.APPROVED) {
                    // @ts-ignore
                    localStorage.setItem('access_token', data['access_token']);
                }

            }).then(()=>{
                this.role_page_router()

            }).catch(error => {
                this.isLoginUnsuccessful = true

            }).finally(()=>{
                this.isLoading = false
            })
        }
    }

    clearAllCookies() {
        const cookies = document.cookie.split(";");

        for (let cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    }

    role_page_router(){
        lastValueFrom(this.authenticationService.getUserProfile()).then((user: User) => {
            this.currentUser = user
            if (this.currentUser.status == Status.APPROVED && this.currentUser.roles[0].name == "ROLE_USER") {
                this.router.navigate(['home']).then();
            }

            if (this.currentUser.status == Status.APPROVED && this.currentUser.roles[0].name == "ROLE_ADMIN") {
                this.router.navigate(['admin/home']).then();
            }


        });
    }


    password_reset_modal_open() {
        this.router.navigate(['reset'])
    }

    go_to_register() {
        this.router.navigate(["register"]).then()
    }

    resolved(captchaResponse: string) {
        this.isRecapture = true;
        this.isRecaptureCheckedError = false;
    }


    safireProcessFlow() {
        this.safireService.startFlow()
    }

    //protected readonly environment = environment;

    closeThirdPartyModal() {
        $('#thirdPartyWarningModal').modal('hide')
    }
}
