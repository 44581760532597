<app-top-navigation></app-top-navigation><br/>

<div class="header" style="margin-top: 80px; text-align: center" *ngIf="!new_rejected_requests?.length">
    <h5>No New or Rejected Storage Requests</h5>
    </div>
<div class="header" style="margin-top: 80px; text-align: center" *ngIf="new_rejected_requests?.length">
    <h4>List Of New And Rejected Storage Requests</h4>
    <div class="content">
<!--        <div class="ui segment basic" style="height: 30vh; margin-top: 120px" *ngIf="new_rejected_requests.length==0">
            <div class="ui active inverted dimmer">
                <div class="ui text loader">Loading</div>
            </div>
        </div>-->
        <div class="ui segment basic" style="margin-top: 10px" >
            <table class="ui single line table small selectable" ><!--style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;"-->
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <!--<th>Project Description</th>-->
                    <!--<th>Expected Growth Per Year</th>-->
                    <!--<th>Storage Type(short/long/both term)</th>-->
                    <th>Requested Amount</th>
                    <!--<th>Initial Storage</th>
                    <th>Expected growth per year</th>
                    <th>Amount Requested</th>-->
                    <th>Request Status</th>
                    <th>Type Of Request</th>
                    <th>View More</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let u of new_rejected_requests; let i = index">
                    <tr>
                        <td>{{u.userResponse.firstName}} {{u.userResponse.lastName}}</td>
                        <td>{{u.userResponse.email}}</td>
                        <!--<td style="">{{u.projectDescription}}</td>-->
                        <!--<td>{{u.userName}}</td>-->
                        <!--<td>{{u.storageType}}</td>-->
                        <!--<td>{{u.storagePeriod}}</td>-->
                        <!--<td>{{u.initialStorageVolume}}</td>-->
                        <!--<td>{{u.expectedStorageSize}}{{u.expectedStorageSizeMetric}}</td>-->
                        <td>{{u.storageSize}}{{u.storageSizeMetric}}</td>
                        <td class="collapsing">
                            <!--                        <span *ngIf="u.storageRequestStatus == 'IDLE'">
                                                        <a class="ui yellow empty circular label"></a>
                                                        Under review
                                                    </span>-->
                            <span *ngIf="u.storageRequestStatus == 'PENDING'">
                            <a class="ui blue empty circular label" *ngIf="u.storageRequestStatus == 'PENDING'"></a>
                            New request
                        </span>
                            <!--                        <span *ngIf="u.storageRequestStatus == 'APPROVED'">
                                                        <a class="ui green empty circular label" *ngIf="u.storageRequestStatus == 'APPROVED'"></a>
                                                        Pending Implementation
                                                    </span>-->
                            <span *ngIf="u.storageRequestStatus == 'REJECTED'">
                            <a class="ui red empty circular label" *ngIf="u.storageRequestStatus == 'REJECTED'"></a>
                            Rejected
                        </span>
                        </td>
                        <td class="collapsing">
                        <span *ngIf="u.storageTerm == 'LONG'">
                            <a class="ui blue empty medium rectangle label" *ngIf="u.storageTerm == 'LONG'"></a>
                            Long Term Archive
                        </span>
                            <span *ngIf="u.storageTerm == 'SHORT'">
                            <a class="ui yellow empty medium rectangle label" *ngIf="u.storageTerm == 'SHORT'"></a>
                            Data Deposit Tool
                        </span>
                            <span *ngIf="u.storageTerm == 'BOTH'">
                            <a class="ui violet empty medium rectangle label" *ngIf="u.storageTerm == 'BOTH'"></a>
                            Short And Long Term Storage
                        </span>
                        </td>
                        <td class="collapsing">
                            <div class="button">
                                <button class="ui button orange" (click)="open_ddt_or_lta_request_info(u,i)">More Info</button>
                            </div>
                        </td>
                        <td class="collapsing">
                            <div>
                                <button class="ui button compact green" (click)="send_approval_email(1, u.id, u.userResponse.email)">Approve</button>
                                &nbsp;<button class="ui button compact red" (click)="open_rejection_modal(u.userResponse,i,u.id)">Reject</button><!-- id="{{i}}b"-->
                                <!--
                                                            <button class="ui button compact yellow" (click)="send_approval_email(1, u.id, u.user.username)">Finished</button>
                                -->
                            </div>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
                <tfoot>
                <tr>
                    <th colspan="8">
                        <div class="ui mini horizontal statistic">
                            <div class="value">
                                {{this.page_number+1}}
                            </div>
                            <div class="label">
                                of {{this.page_count+1}}
                            </div>
                        </div>
                        <div class="ui right floated pagination menu">
                            <a class="icon item" (click)="prev_page()" *ngIf="start_count > 1">
                                <i class="left chevron icon"></i>
                            </a>
                            <a class="item" *ngFor="let i of [].constructor(page_count+1); let index = index">{{index+1}}</a>

                            <a class="icon item" (click)="next_page()" *ngIf="maximised==false">
                                <i class="right chevron icon"></i>
                            </a>
                        </div>
                    </th>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<div class="header" style="margin-top: 30px; text-align: center" *ngIf="!approved_implemented_requests?.length">
    <h5>No Approved or Pending Approval Storage Requests</h5>
</div>
<div class="header" style="margin-top: 30px; text-align: center" *ngIf="approved_implemented_requests?.length">
    <h4>List Of Approved And Pending Implementation Storage Requests</h4>
    <div class="content">
<!--        <div class="ui segment basic" style="height: 50vh; margin-top: 120px" *ngIf="approved_implemented_requests.length==0">
            <div class="ui active inverted dimmer">
                <div class="ui text loader">Loading</div>
            </div>
        </div>-->
        <div class="ui segment basic" style="margin-top: 10px" >
            <table class="ui single line table small selectable" ><!--style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;"-->
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <!--<th>Project Description</th>-->
                    <!--<th>Expected growth per year</th>-->
                    <!--<th>Email</th>-->
                    <!--<th>Storage Type(short/long/both term)</th>-->
                    <!--<th>Period</th>-->
                    <!--<th>Initial Storage</th>-->

                    <th>Requested Amount</th>
                    <th>Request Status</th>
                    <th>Type Of Request</th>
                    
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let u of approved_implemented_requests; let i = index">
                    <tr>
                        <td>{{u.userResponse.firstName}} {{u.userResponse.lastName}}</td>
                        <td>{{u.userResponse.email}}</td>
                        <!--<td style="">{{u.projectDescription}}</td>-->
                        <!--<td>{{u.userName}}</td>-->
                        <!--<td>{{u.storageType}}</td>-->
                        <!--<td>{{u.storagePeriod}}</td>-->
                        <!--<td>{{u.initialStorageVolume}}</td>-->
                        <!--<td>{{u.expectedStorageSize}}{{u.expectedStorageSizeMetric}}</td>-->
                        <td>{{u.storageSize}}{{u.storageSizeMetric}}</td>

                        <td class="collapsing">
                <span *ngIf="u.storageRequestStatus == 'APPROVED'">
                            <a class="ui yellow empty circular label" *ngIf="u.storageRequestStatus == 'APPROVED'"></a>
                            Pending Implementation
                </span>
                            <span *ngIf="u.storageRequestStatus == 'IMPLEMENTED'">
                        <a class="ui green empty circular label" *ngIf="u.storageRequestStatus == 'IMPLEMENTED'"></a>
                        Implemented
                </span>
                        </td>

                        <td class="collapsing">
                        <span *ngIf="u.storageTerm == 'LONG'">
                            <a class="ui blue empty medium rectangle label" *ngIf="u.storageTerm == 'LONG'"></a>
                            Long Term Archive
                        </span>
                            <span *ngIf="u.storageTerm == 'SHORT'">
                            <a class="ui yellow empty medium rectangle label" *ngIf="u.storageTerm == 'SHORT'"></a>
                            Data Deposit Tool
                        </span>
                            <span *ngIf="u.storageTerm == 'BOTH'">
                            <a class="ui violet empty medium rectangle label" *ngIf="u.storageTerm == 'BOTH'"></a>
                            Short And Long Term Storage
                        </span>
                        </td>

                        <td class="collapsing">
                            <div>
                                <button class="ui button compact yellow" (click)="send_approval_email(3, u.id, u.userResponse.email)" *ngIf="u.storageRequestStatus == 'APPROVED'">Finished</button>
                                <button class="ui button compact green" disabled *ngIf="u.storageRequestStatus == 'IMPLEMENTED'">Completed</button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
                <tfoot>
                <tr>
                    <th colspan="8">
                        <div class="ui mini horizontal statistic">
                            <div class="value">
                                {{this.page_number_+1}}
                            </div>
                            <div class="label">
                                of {{this.page_count_+1}}
                            </div>
                        </div>
                        <div class="ui right floated pagination menu">
                            <a class="icon item" (click)="prev_page_()" *ngIf="start_count_ > 1">
                                <i class="left chevron icon"></i>
                            </a>
                            <a class="item" *ngFor="let i of [].constructor(page_count_+1); let index = index">{{index+1}}</a>

                            <a class="icon item" (click)="next_page_()" *ngIf="maximised_==false">
                                <i class="right chevron icon"></i>
                            </a>
                        </div>
                    </th>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<div class="ui modal small" id="users_rejection_modal">
    <button class="ui button compact" style="float: right" (click)="rejection_modal_close()">X</button>
    <div class="header">Storage Rejection</div>
    <div class="content">

        <div class="ui grid">
            <div class="eight wide column">
                <h4 class="ui image header">
                    <div class="content">
                        Names:{{current_user?.firstName | titlecase}} {{current_user?.lastName | titlecase}}
                        <div class="sub header">Email Addres:{{current_user?.email}}
                        </div>
                    </div>
                </h4>
            </div>
            <div class="eight wide column">
                <h4 class="ui image header">
                    <img src="../../../assets/images/{{current_user?.institution?.imageName}}" class="ui mini rounded image">
                    <div class="content">
                        Institution Name:{{current_user?.institution?.name | titlecase}}
                        <div class="sub header">Institution Abbreviation:{{current_user?.institution?.abbreviation | uppercase}}
                        </div>
                    </div>
                </h4>
            </div>
        </div>
        <div class="ui divider"></div>
        <form class="ui form" [formGroup]="rejection_reason_form" (ngSubmit)="rejection_reason_form_submit()">

            <div class="field" [class.error]="this.rejection_reason_form.get('id')?.invalid ">
                <label>Rejection Reason</label>
                <select class="ui dropdown" formControlName="id" id="reasons">
                    <option value=""></option>
                    <ng-container *ngFor="let t of rejection_reason_list">
                        <option value="{{t.id}}">{{t.reason}}</option>
                    </ng-container>
                </select>
            </div>

            <button class="ui button compact orange" type="submit" [ngClass]="{'loading disabled': is_reject_loading}" >Submit</button>
        </form>
    </div>
</div>

<div class="ui modal medium" id="ddt_info_modal">
    <a class="ui button compact" style="float: right" (click)="ddt_modal_close()">X</a><!--style="transform: translateX(1180%)"-->
    <div class="header">More Info on Data Deposit Tool Request</div>
    <div class="form-shadow">
        <h5 class="ui medium header">Plan for data retirement:</h5><br>
        <div class="content">
            <div class="field">
                <!--<label>Primary custodian institution:</label>-->
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.storageDataRetirement}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Plan for data preservation:</h5><br>
        <div class="content">
            <div class="field">
                <!--<label>Primary custodian institution:</label>-->
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.storageDataPreservation}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Motivation:</h5><br>
        <div class="content">
            <div class="field">
                <!--<label>Primary custodian institution:</label>-->
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.storageRequestMotivationDDT}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Intended storage period:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="1" cols="50" placeholder="{{current_requests_?.ddtStoragePeriod}} {{months}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Intellectual Property:</h5><br>
        <div class="content">
            <div class="field">
                <!--<label>Primary custodian institution:</label>-->
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.storageIntellectualProperty}}:{{current_requests_?.storageIntellectualPropertyOwner}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="ui modal medium" id="lta_info_modal" >
    <button class="ui button compact" style="float: right" (click)="lta_modal_close()">X</button><!--style="transform: translateX(1180%)"-->
    <div class="header">More Info on Long Term Archive Request</div>
    <div class="form-shadow">
        <h5 class="ui medium header">Primary custodian institution:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.userResponse?.institution?.name}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Primary custodian Department/Division:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.primaryCustodianDepartment}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Primary custodian Phone Number:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.primaryCustodianPhoneNumber}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Secondary custodian First Name:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.secondaryCustodianFirstName}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Secondary custodian Last Name:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.secondaryCustodianLastName}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Secondary custodian Email address:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.secondaryCustodianEmail}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Secondary custodian Institution:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.institution?.name}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Secondary custodian Division/Department:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.secondaryCustodianDepartment}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Secondary custodian Phone Number:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.secondaryCustodianPhoneNumber}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Project description:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.projectDescription}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Motivation for request:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.storageRequestMotivation}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Description of data:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.storageDataDescription}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
<!--    <div class="form-shadow">
        <h5 class="ui medium header">Intended period for storage:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.estimatedStoragePeriod}}{{current_requests_?.estimatedStoragePeriodMetric}}" style="border: none"></textarea>
            </div>
        </div>
    </div>-->
    <div class="form-shadow">
        <h5 class="ui medium header">Intended storage period:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="1" cols="50" placeholder="{{current_requests_?.ltaStoragePeriod}} {{years}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
    <div class="form-shadow">
        <h5 class="ui medium header">Intellectual property ownership:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.storageIntellectualProperty}}: {{current_requests_?.storageIntellectualPropertyOwner}}" style="border: none"></textarea>
            </div>
        </div>
    </div>
<!--    <div class="form-shadow">
        <h5 class="ui medium header">Level of opennes of the stored data:</h5><br>
        <div class="content">
            <div class="field">
                <textarea readonly rows="2" cols="50" placeholder="{{current_requests_?.storageDataSpectrum}}" style="border: none"></textarea>
            </div>
        </div>
    </div>-->
</div>


