<app-top-navigation></app-top-navigation>

<div class="ui container" style="margin-top: 100px">
    <h4 class="text-center">Create Notification</h4>
    <form class="ui form" (ngSubmit)="notificationFrom.form.valid && onSubmit()" #notificationFrom ="ngForm">

           <div class="field">
              <label>Subject:</label>
             <input type="text" [(ngModel)]="notificationRequest.subject" name="subject" placeholder="Enter Subject" #subject="ngModel" required>
             <label *ngIf="(notificationFrom.submitted) && subject.errors?.['required']" style="color: red">
                 Please enter your subject as required.
                 </label>
           </div>
    <div class="ui form">
        <div class="field">
          <label>Message:</label>
          <textarea rows="2" [(ngModel)]="notificationRequest.message" name="message" placeholder="Enter the message or notification" #message="ngModel" maxlength="10000" required></textarea>
          <label *ngIf="message.value?.length">
              {{message.value.length}} characters (Max: 10000)
          </label>
          <label *ngIf="(notificationFrom.submitted) && message.errors?.['required']" style="color: red">
              Please enter the message or notification as required.
          </label>
        </div>
        </div>
        <br/><br/>
 <button class="ui orange button" type="submit">Submit</button>
 <button [routerLink]="['/admin/notifications']" style="float: right" button class="ui button compact">Cancel</button>
 <br/><br/>
</form>
</div>
