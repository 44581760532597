import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AuthenticationService} from './services/auth/authentication.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InterceptorService} from './services/interceptor/interceptor.service';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthGuard} from "./guards/auth/auth.guard";
import {RecaptchaModule} from "ng-recaptcha";
import { AdminComponent } from './admin/admin.component';
import {NgOptimizedImage} from "@angular/common";
import {RegisterComponent} from "./register/register.component";
import {HomeComponent} from "./home/home.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {TopNavigationComponent} from "./top-navigation/top-navigation.component";
import {LoginComponent} from "./login/login.component";
import {IndexTopMenuComponent} from "./index-top-menu/index-top-menu.component";
import {FooterComponent} from "./footer/footer.component";
import {AdminHomeComponent} from "./admin-home/admin-home.component";
import {SysAdminPageComponent} from "./sys-admin-page/sys-admin-page.component";
import {StorageRequestComponent} from "./storage-request/storage-request.component";
import {CustomScrollDirective} from "./custom-scroll.directive";
import {MaxLengthDirective} from "./max-length.directive";
import { GethelpListComponent } from './gothelp/gethelp-list/gethelp-list.component';
import { GethelpCreateComponent } from './gothelp/gethelp-create/gethelp-create.component';
import { GethelpUpdateComponent } from './gothelp/gethelp-update/gethelp-update.component';
import { FilterPipe } from './filter.pipe';
import { ActivateEmailComponent } from './activate-email/activate-email.component';
import { DeactivateEmailComponent } from './deactivate-email/deactivate-email.component';
import { StorageApplicationStatusComponent } from './storage-application-status/storage-application-status.component';
import { SafireComponent } from './safire/safire.component';
import {OAuthModule} from "angular-oauth2-oidc";
import {
    NotificationCreateComponent
} from "./notification-message/notification-create/notification-create/notification-create.component";
import {
    NotificationUpdateComponent
} from "./notification-message/notification-update/notification-update/notification-update.component";
import {
    NotificationListComponent
} from "./notification-message/notification-list/notification-list/notification-list.component";
import {AdministratoruserComponent} from "./administrator-user/administrator-list/administratoruser.component";
import {
    CreateadminstratorComponent
} from "./administrator-user/administrator-create/createadminstrator/createadminstrator.component";
import {
    UpdateadministratorComponent
} from "./administrator-user/administrator-update/updateadministrator/updateadministrator.component";
import { ConfirmComponent } from './confirm/confirm.component';
import { FailedEmailListComponent } from './administrator-user/failed-email-list/failed-email-list.component';
import { OrcidComponent } from './orcid/orcid.component';
@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    HomeComponent,
    ResetPasswordComponent,
    TopNavigationComponent,
    LoginComponent,
    IndexTopMenuComponent,
    FooterComponent,
    AdminHomeComponent,
    AdminComponent,
      SysAdminPageComponent,
      StorageRequestComponent,
      CustomScrollDirective,
      MaxLengthDirective,

      AdministratoruserComponent,
      CreateadminstratorComponent,
      UpdateadministratorComponent,
      GethelpListComponent,
      GethelpCreateComponent,
      GethelpUpdateComponent,
     FilterPipe,
      ActivateEmailComponent,
      DeactivateEmailComponent,
      StorageApplicationStatusComponent,
      SafireComponent,
      NotificationCreateComponent,
      NotificationUpdateComponent,
      NotificationListComponent,
      ConfirmComponent,
      FailedEmailListComponent,
      OrcidComponent,
  ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RecaptchaModule,
        NgOptimizedImage,
        OAuthModule.forRoot()
    ],
  providers: [AuthenticationService,AuthGuard,
      {
          provide: HTTP_INTERCEPTORS,
          useClass: InterceptorService,
          multi: true
      }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

