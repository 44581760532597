import {Component, OnInit} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {StorageRequest, StorageRequestStatus, storageTerm} from "../model/Storagerequest";
import {StorageRequestService} from "../services/storage-request/storage-request.service";
import {User} from "../model/User";
import {RejectionService} from "../services/rejection/rejection.service";
import {RejectionReason} from "../model/RegectionReason";
import {AuthenticationService} from "../services/auth/authentication.service";


declare var  $:any
declare var  toastr:any
@Component({
  selector: 'app-sys-admin-page',
  templateUrl: './sys-admin-page.component.html',
  styleUrls: ['./sys-admin-page.component.css']
})
export class SysAdminPageComponent implements OnInit{

    all_requests_list: StorageRequest[] = [];
    //all_by_username: StorageRequest[] = [];
    all_requests_list_updated: StorageRequest | null = null;
    approved_implemented_requests: StorageRequest[] = [];
    new_rejected_requests: StorageRequest[] = [];
    private current_user_index: number = 0;
    rejection_reason_list: RejectionReason[] = [];
    current_user: User | undefined = undefined;
    requests_list: StorageRequest[] = [];
    requests_list_: StorageRequest[] = [];
    is_reject_loading: boolean | undefined = undefined;
    page_count: number = 0;
    page_count_: number = 0;
    page_number: number = 0;
    page_number_: number = 0;
    start_count: number = 0;
    start_count_: number = 0;
    maximised: boolean = false;
    maximised_: boolean = false;
    private total_size: number = 0
    private total_size_: number = 0
    remainder: number = 0
    remainder_: number = 0

    current_requests: StorageRequest | null = null;
    current_requests_: StorageRequest | null = null;
    current_request: StorageRequest | null = null;
    current_request_index: number = 0;

    months:string="Months";
    years:string="Years";
    storageId!: number;
  
    constructor(private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private storageRequestService:StorageRequestService,
                private router:Router,
                private rejectionService:RejectionService) {
    }

    ngOnInit(): void {
        lastValueFrom(this.storageRequestService.get_all_requests("")).then((requests: StorageRequest[]) => {
            this.all_requests_list = requests
            this.get_approved_list()
        }).catch((error)=>{
            //this.router.navigate([""])
        });

    }
    get_approved_list()
    {
        for (let request = 0; request < this.all_requests_list.length; request++)
        {
            if (this.all_requests_list[request].storageRequestStatus == 'APPROVED' || this.all_requests_list[request].storageRequestStatus == 'IMPLEMENTED')
            {
                this.approved_implemented_requests.push(this.all_requests_list[request])
            }
            else if (this.all_requests_list[request].storageRequestStatus == 'PENDING' || this.all_requests_list[request].storageRequestStatus == 'REJECTED')
            {
                this.new_rejected_requests.push(this.all_requests_list[request])
            }
        }
    }

    send_approval_email(status: number, id: number, username: string)
    {
        //this.disable_finish_button = false;
        lastValueFrom(this.storageRequestService.send_approval_or_rejection_to_requester({
            "storageRequestStatus":status,
            "id":id,
            "username":username
        })).then((requests: StorageRequest) => {
            toastr.options = {
                "progressBar": true,
                "positionClass": "toast-top-right"
            }
            toastr["success"]("Storage Increase Implementation successfully and Approval Email Sent to the User.");

            $('#tc_modal').modal('hide');
            this.redirectToUserList();
           // this.router.navigate(['/admin/showallrequests']).then();

        }).
        catch((error)=>{
        });

    }
    send_rejection_email(status: number, id: number, username: string, reason:any)
    //send_rejection_email(status: number, username: string, s: string, i: number, reason:any)
    {
        lastValueFrom(this.storageRequestService.send_approval_or_rejection_to_requester({
            "storageRequestStatus":status,
            "id":id,
            "username":username,
            "rejectionReason": parseInt(reason)
        })).then((requests: StorageRequest) => {
            toastr.options = {
                "progressBar": true,
                "positionClass": "toast-top-right"
            }
            toastr["success"]("Rejection Email sent")

            $('#tc_modal').modal('hide');
           // this.router.navigate(['/admin/showallrequests']).then();
           this.redirectToUserList();

        }).catch((error)=>{
        
        }).finally(()=>{
            this.is_reject_loading = false
            $('#users_rejection_modal').modal('hide');
        });
    }
    rejection_reason_form = this.formBuilder.group({
        id: ['',[ Validators.required]]
    });

    open_rejection_modal(user: User, i: number,storageId:number){
        this.storageId = storageId;
        this.current_user_index = i;

        lastValueFrom(this.rejectionService.getListOfStorageRejection()).then(data=>{
            this.rejection_reason_list = data;
        }).finally(()=>{
            $('#reasons').dropdown();
        })

        this.current_user = user;
        $('#users_rejection_modal').modal('show');
    }
    redirectToUserList() {
        window.location.href="/admin/showallrequests"
      }
     rejection_reason_form_submit() {
                // @ts-ignore
                this.send_rejection_email(2, this.storageId, this.current_user.email, this.rejection_reason_form.getRawValue().id); 
             }

    next_page() {
        this.page_number = this.page_number+1
        this.start_count = this.start_count+10

        if (((this.page_number+1)*10) > this.total_size) {
            this.maximised = true
            lastValueFrom(this.storageRequestService.get_requests(this.start_count, this.remainder)).then((requests: StorageRequest[]) => {
                this.requests_list = requests
            }).then(() => {})
        }else{
            lastValueFrom(this.storageRequestService.get_requests(this.start_count, 9)).then((requests: StorageRequest[]) => {
                this.requests_list = requests
            }).then(() => {})
        }
    }
    next_page_() {
        this.page_number_ = this.page_number_+1
        this.start_count_ = this.start_count_+10

        if (((this.page_number_+1)*10) > this.total_size_) {
            this.maximised_ = true
            lastValueFrom(this.storageRequestService.get_requests(this.start_count_, this.remainder_)).then((requests: StorageRequest[]) => {
                this.requests_list_ = requests
            }).then(() => {})
        }else{
            lastValueFrom(this.storageRequestService.get_requests(this.start_count_, 9)).then((requests: StorageRequest[]) => {
                this.requests_list_ = requests
            }).then(() => {})
        }
    }

    prev_page() {
        this.maximised = false
        this.page_number = this.page_number-1
        this.start_count = this.page_number*10

        if(this.start_count == 0){
            this.start_count = 1
        }
        lastValueFrom(this.storageRequestService.get_requests(this.start_count, 9)).then((requests: StorageRequest[]) => {
            this.requests_list = requests
        }).then(() => {})

    }

    prev_page_() {
        this.maximised_ = false
        this.page_number_ = this.page_number_-1
        this.start_count_ = this.page_number_*10

        if(this.start_count_ == 0){
            this.start_count_ = 1
        }
        lastValueFrom(this.storageRequestService.get_requests(this.start_count, 9)).then((requests: StorageRequest[]) => {
            this.requests_list_ = requests
        }).then(() => {})

    }

    open_ddt_or_lta_request_info(storageRequest: StorageRequest, index: number)
    {

        lastValueFrom(this.storageRequestService.get_requests_by_username(storageRequest.userResponse.email)).then((requests: StorageRequest[]) => {
            for(let each = 0; each < requests.length; each++)
            {
                if(requests[each].storageRequestStatus == StorageRequestStatus.PENDING || requests[each].storageRequestStatus == StorageRequestStatus.REJECTED)
                {
                    this.current_requests_ = requests[each]
                    // @ts-ignore
                    if(this.current_requests_?.storageTerm == storageTerm.SHORT)
                    {
                        $('#ddt_info_modal').modal('show');

                    }// @ts-ignore
                    else if (this.current_requests_.storageTerm == storageTerm.LONG)
                    {
                        $('#lta_info_modal').modal('show');
                    }
                }else if(requests[each].storageRequestStatus == StorageRequestStatus.APPROVED || requests[each].storageRequestStatus == StorageRequestStatus.IMPLEMENTED)
                {
                    this.current_requests = requests[each];
                }
            }
        }).catch((error)=>{
        })
        this.current_request_index = index

    }

    ddt_modal_close() {
        $('#ddt_info_modal').modal('hide');
    }
    lta_modal_close() {
        $('#lta_info_modal').modal('hide');
    }
    rejection_modal_close() {
        $('#users_rejection_modal').modal('hide');
    }
    protected readonly undefined = undefined;
}
