import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-index-top-menu',
  templateUrl: './index-top-menu.component.html',
  styleUrls: ['./index-top-menu.component.css']
})
export class IndexTopMenuComponent {

    constructor(private router:Router) {
    }
    go_to_register() {
        this.router.navigate(['register'])
    }

    go_to_login() {
        this.router.navigate([''])
    }

}
