import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationModel } from 'src/app/model/Notification';
import { NotificationRequest } from 'src/app/model/NotificationsRequest';
import { PublishNotification } from 'src/app/model/PublishNotificationRequest';
import { NotificationService } from 'src/app/services/notification/notification.service';
declare var  toastr:any;
declare var  $:any;
@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit{
  notifications: NotificationModel[] | undefined;
  updateNotificationRequest:NotificationRequest= new NotificationRequest();
  publishNotification: PublishNotification = new PublishNotification();
  idNum:number = 0;
  ngOnInit(): void {
    this.getListOfNotification();
  }
  constructor(private notificationService: NotificationService,
    private router: Router){

  }
  updateNotification(id: number) {
    this.router.navigate(['admin/updatenotification', id]);
  }
  createNotification(){
    this.router.navigate(['admin/createnotification']);
  }
  redirectToNotificationList() {
    this.router.navigate(['/admin/notifications']);
  }
  getListOfNotification(){
    this.notificationService.getAllNotifications().subscribe(data =>{
      this.notifications = data;
    })
  }

  deleteNotification(id: number) {
    this.notificationService.deleteNotification(id).subscribe(data => {
      toastr.options = {
        "progressBar": true,
        "positionClass": "toast-top-right"
    }
    toastr["success"]("success", "Delete the Notification successfully");
    $("#confirmationDeletionNotification").modal("hide");
    this.getListOfNotification();
    });
    this.redirectToNotificationList();
  }
  publishNotificationToUser(id: number) {
    this.idNum = id;
    this.notificationService.publishNotification(id,this.publishNotification).subscribe(data => {
      this.getListOfNotification();
      toastr.options = {
        "progressBar": true,
        "positionClass": "toast-top-right"
    }
    toastr["success"]("success", "The Notification is send to users successfully");
    $("#confirmationPublishNotification").modal("hide");
    });
    this.redirectToNotificationList();
  }
  confirmPublish(id:number){
    this.idNum = id;
    $("#confirmationPublishNotification").modal("show");
  }
  confirmDelete(id:number){
    this.idNum = id;
    $("#confirmationDeletionNotification").modal("show");
  }
  notSurePublishButton(){
    $("#confirmationPuconfirmationPublishNotificationblishMessage").modal("hide");
  }
  notSureDeleteButton(){
    $("#confirmationDeletionNotification").modal("hide");
  }


}
