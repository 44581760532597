import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';
import { lastValueFrom } from 'rxjs';
import { error } from 'jquery';
import { Status, User } from '../model/User';

@Component({
  selector: 'app-activate-email',
  templateUrl: './activate-email.component.html',
  styleUrls: ['./activate-email.component.css']
})
export class ActivateEmailComponent implements OnInit{

  username!: string;
  active : User | undefined
  
  constructor(private router:Router,private autheticationservice:AuthenticationService,private route:ActivatedRoute)
  {}

  ngOnInit(): void {
   
    this.username = this.route.snapshot.params['username']
     lastValueFrom(this.autheticationservice.activate_email(this.username)).then((user : User) => {
      this.active = user;
      {
      if (this.active.status !== Status.PENDING) {
        this.router.navigate(['emailconfirmed']).then();
       }

    }
  }
      )};
  }




