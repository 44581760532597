import {Component, ElementRef, ViewChild, OnInit, Input} from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from "@angular/forms";
//import {StorageRequestService} from "../../services/auth/authentication.service";
import {lastValueFrom} from "rxjs";
import {Router} from "@angular/router";
import {StorageRequestService} from "../services/storage-request/storage-request.service";
import {AuthenticationService} from "../services/auth/authentication.service";
import {User} from "../model/User";
import {Chart, registerables} from "chart.js";
import {StorageRequest} from "../model/Storagerequest";
import {Institution} from "../model/Institution";
import {InstitutionService} from "../services/institution/institution.service";
import { HttpErrorResponse } from '@angular/common/http';
declare var  l:any
declare var  toastr:any
@Component({
    selector: 'app-storage-request',
    templateUrl: './storage-request.component.html',
    styleUrls: ['./storage-request.component.css']
})
export class StorageRequestComponent implements OnInit {
    l: any;
    current_user: User | null = null
    storage_request_list: StorageRequest[] = []
    term_click_listener: boolean | undefined = undefined;
    intellectual_click_listener: boolean | undefined = undefined;
    institutionList: Institution[] = []
    is_loading: boolean = false
    term_requested_listener: boolean = false;
    ddt_form_selected: boolean | undefined = undefined;
    lta_form_selected: boolean | undefined = undefined;
       // @ts-ignore
    current_req: StorageRequest[] = []



    constructor(private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private storageRequestService: StorageRequestService,
                private router: Router,
                private institutionService: InstitutionService) {
        Chart.register(...registerables);
    }


    ngOnInit(): void {

        lastValueFrom(this.authenticationService.getUserProfile()).then((user: User) => {
            this.current_user = user
        }).then(() => {
            lastValueFrom(this.storageRequestService.get_all_requests(this.current_user?.email)).then((storageRequests: StorageRequest[]) => {
                this.storage_request_list = storageRequests
            }).catch((error) => {
            });
        }).catch((error) => {
        });

        lastValueFrom(this.institutionService.read_all()).then((data) => {
            this.institutionList = data
        }).catch(error => {
        });
    }


    storage_request_form = this.formBuilder.group({
        storage_term: ['', Validators.required],
    });

    storage_request_form_ddt = this.formBuilder.group({
        storage_data_retirement: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
        storage_data_preservation: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
        project_motivation_ddt: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(4000)]],
        storage_size_ddt: ['', [Validators.required, Validators.minLength(1)]],
        storage_size_metric_ddt: ['', [Validators.required, Validators.minLength(1)]],
        ddt_storage_period: ['', [Validators.required, Validators.minLength(1)]],
        intellectual_property_ddt: ['', [Validators.required, Validators.minLength(1)]],
        intellectual_property_owner_ddt: [null, Validators.maxLength(500)]
    });


    storage_request_form_lta = this.formBuilder.group({
        //primary custodian
        primary_custodian_department: ['', [Validators.required, Validators.minLength(1)]],
        primary_custodian_phone_number: ['', [Validators.required, Validators.minLength(1)]],
        //secondary custodian
        secondary_custodian_first_name: ['', [Validators.required, Validators.minLength(1)]],
        secondary_custodian_last_name: ['', [Validators.required, Validators.minLength(1)]],
        secondary_custodian_email: ['', [Validators.required, Validators.email]],
        secondary_custodian_institution: ['', Validators.required],
        secondary_custodian_department: ['', [Validators.required, Validators.minLength(1)]],
        secondary_custodian_phone_number: ['', [Validators.required, Validators.minLength(1)]],
        storage_size_lta: ['', [Validators.required, Validators.minLength(1)]],
        storage_size_metric_lta: ['', [Validators.required, Validators.minLength(1)]],
        lta_storage_period: ['', [Validators.required, Validators.minLength(1)]],
        project_description_lta: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(5000)]],
        project_motivation_lta: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(5000)]],
        data_description_lta: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(4000)]],
        intellectual_property_lta: ['', [Validators.required, Validators.minLength(1)]],
        intellectual_property_owner_lta: [null, Validators.maxLength(500)]
    });

    storage_request_form_submit() {
        this.is_loading = true

        if (this.ddt_form_selected) {
            // @ts-ignore
            lastValueFrom(this.storageRequestService.send_requester_to_queue(
                {
                    "storageRequestMotivationDDT": this.storage_request_form_ddt.get('project_motivation_ddt')?.value,
                    "storageTerm": this.storage_request_form.get('storage_term')?.value,
                    "storageDataRetirement": this.storage_request_form_ddt.get('storage_data_retirement')?.value,
                    "storageDataPreservation": this.storage_request_form_ddt.get('storage_data_preservation')?.value,
                    "storageIntellectualProperty": this.storage_request_form_ddt.get('intellectual_property_ddt')?.value,
                    "storageIntellectualPropertyOwner": this.storage_request_form_ddt.get('intellectual_property_owner_ddt')?.value,
                    "storageSize": this.storage_request_form_ddt.get('storage_size_ddt')?.value,
                    "storageSizeMetric": this.storage_request_form_ddt.get('storage_size_metric_ddt')?.value,
                    "ddtStoragePeriod": this.storage_request_form_ddt.get('ddt_storage_period')?.value,

                }
            )).then(data => {
                this.router.navigate(['/home']).then();
                toastr.options = {
                    "progressBar": true,
                    "positionClass": "toast-top-right"
                }
                toastr["success"]("success", "Your storage request is created successfully and administrator will action your request.")
                l('#tc_modal').modal('hide');

            }).catch(error => {
                if (error instanceof HttpErrorResponse) {
                        toastr.options = {
                            "progressBar": true,
                            "positionClass": "toast-top-right"
                        }
                        toastr["warning"]("Your are having pending storage request, the pending request must completed before you can make another storage request!!!")
                        l('#tc_modal').modal('hide');


                }
            }).finally(() => {
                this.is_loading = false
            })
        } else if (this.lta_form_selected) {
            // @ts-ignore
            lastValueFrom(this.storageRequestService.send_requester_to_queue(
                {
                    //Primary custodian
                    "primaryCustodianPhoneNumber": this.storage_request_form_lta.get('primary_custodian_phone_number')?.value,
                    "primaryCustodianDepartment": this.storage_request_form_lta.get('primary_custodian_department')?.value,
                    //Secondary custodian
                    "secondaryCustodianFirstName": this.storage_request_form_lta.get('secondary_custodian_first_name')?.value,
                    "secondaryCustodianLastName": this.storage_request_form_lta.get('secondary_custodian_last_name')?.value,
                    "secondaryCustodianEmail": this.storage_request_form_lta.get('secondary_custodian_email')?.value,
                    // @ts-ignore
                    "institutionId": parseInt(this.storage_request_form_lta.get('secondary_custodian_institution')?.value),
                    "secondaryCustodianPhoneNumber": this.storage_request_form_lta.get('secondary_custodian_phone_number')?.value,
                    "secondaryCustodianDepartment": this.storage_request_form_lta.get('secondary_custodian_department')?.value,
                    //Storage request metadata
                    "projectDescription": this.storage_request_form_lta.get('project_description_lta')?.value,
                    "storageRequestMotivation": this.storage_request_form_lta.get('project_motivation_lta')?.value,
                    "storageDataDescription": this.storage_request_form_lta.get('data_description_lta')?.value,
                    "storageTerm": this.storage_request_form.get('storage_term')?.value,
                    "storageIntellectualProperty": this.storage_request_form_lta.get('intellectual_property_lta')?.value,
                    "storageIntellectualPropertyOwner": this.storage_request_form_lta.get('intellectual_property_owner_lta')?.value,
                    "storageSize": this.storage_request_form_lta.get('storage_size_lta')?.value,
                    "storageSizeMetric": this.storage_request_form_lta.get('storage_size_metric_lta')?.value,
                    "ltaStoragePeriod": this.storage_request_form_lta.get('lta_storage_period')?.value,

                }
            )).then(data => {
                this.router.navigate(['/home']).then();
                toastr.options = {
                    "progressBar": true,
                    "positionClass": "toast-top-right"
                }
                toastr["success"]("success", "Your storage request is created successfully and administrator will action your request.")
                l('#tc_modal').modal('hide');

            }).catch(error => {
                if (error instanceof HttpErrorResponse) {
                        toastr.options = {
                            "progressBar": true,
                            "positionClass": "toast-top-right"
                        }
                        toastr["warning"]("Your are having pending storage request, the pending request must completed before you can make another storage request!!!")
                        l('#tc_modal').modal('hide');

                }/* else {
                    toastr.options = {
                        "progressBar": true,
                        "positionClass": "toast-top-right"
                    }
                    toastr["warning"]("Your request is pending implementation")
                    l('#tc_modal').modal('hide');
                    this.router.navigate(['/home']).then();
                }*/
            }).finally(() => {
                this.is_loading = false
            })
        }

    }


    termClickListener() {

        // @ts-ignore
        if (this.storage_request_form.get('storage_term')?.value == "SHORT") {
            this.storage_request_form_lta.reset();
            this.ddt_form_selected = true;
            this.term_click_listener = true;
            this.term_requested_listener = true;

        } // @ts-ignore
        else if (this.storage_request_form.get('storage_term')?.value == "LONG") {
            this.storage_request_form_ddt.reset();
            this.lta_form_selected = true;
            this.term_click_listener = false;
            this.term_requested_listener = true;

        }

    }

    intellectualPropertyOwner() {
        // @ts-ignore
        if (this.storage_request_form_ddt.get('intellectual_property_ddt')?.value == "YES" || this.storage_request_form_lta.get('intellectual_property_lta')?.value == "YES") {
            this.intellectual_click_listener = true;

        } // @ts-ignore
        else if (this.storage_request_form_ddt.get('intellectual_property_ddt')?.value == "NO" || this.storage_request_form_lta.get('intellectual_property_lta')?.value == "NO") {
            this.intellectual_click_listener = false;
        }
    }


    get storage_data_retirement() {
        return this.storage_request_form_ddt.get('storage_data_retirement')?.invalid
    }

    get storage_term() {
        return this.storage_request_form.get('storage_term')?.invalid
    }

    get storage_data_preservation() {
        return this.storage_request_form_ddt.get('storage_data_preservation')?.invalid
    }

    get project_motivation_ddt() {
        return this.storage_request_form_ddt.get('project_motivation_ddt')?.invalid
    }

    get storage_size_ddt() {
        return this.storage_request_form_ddt.get('storage_size_ddt')?.invalid
    }

    get storage_size_metric_ddt() {
        return this.storage_request_form_ddt.get('storage_size_metric_ddt')?.invalid
    }

    get ddt_storage_period() {
        return this.storage_request_form_ddt.get('ddt_storage_period')?.invalid
    }

    get intellectual_property_ddt() {
        return this.storage_request_form_ddt.get('intellectual_property_ddt')?.invalid
    }

    get intellectual_property_owner_ddt() {
        return this.storage_request_form_ddt.get('intellectual_property_owner_ddt')?.invalid
    }

    get primary_custodian_department() {
        return this.storage_request_form_lta.get('primary_custodian_department')?.invalid
    }

    get primary_custodian_phone_number() {
        return this.storage_request_form_lta.get('primary_custodian_phone_number')?.invalid
    }

    get secondary_custodian_first_name() {
        return this.storage_request_form_lta.get('secondary_custodian_first_name')?.invalid
    }

    get secondary_custodian_last_name() {
        return this.storage_request_form_lta.get('secondary_custodian_last_name')?.invalid
    }

    get secondary_custodian_email() {
        return this.storage_request_form_lta.get('secondary_custodian_email')?.invalid
    }

    get secondary_custodian_institution() {
        return this.storage_request_form_lta.get('secondary_custodian_institution')?.invalid
    }

    get secondary_custodian_department() {
        return this.storage_request_form_lta.get('secondary_custodian_department')?.invalid
    }

    get secondary_custodian_phone_number() {
        return this.storage_request_form_lta.get('secondary_custodian_phone_number')?.invalid
    }

    get storage_size_lta() {
        return this.storage_request_form_lta.get('storage_size_lta')?.invalid
    }

    get storage_size_metric_lta() {
        return this.storage_request_form_lta.get('storage_size_metric_lta')?.invalid
    }

    get lta_storage_period() {
        return this.storage_request_form_lta.get('lta_storage_period')?.invalid
    }

    get project_description_lta() {
        return this.storage_request_form_lta.get('project_description_lta')?.invalid
    }

    get project_motivation_lta() {
        return this.storage_request_form_lta.get('project_motivation_lta')?.invalid
    }

    get data_description_lta() {
        return this.storage_request_form_lta.get('data_description_lta')?.invalid
    }

    get intellectual_property_lta() {
        return this.storage_request_form_lta.get('intellectual_property_lta')?.invalid
    }

    get intellectual_property_owner_lta() {
        return this.storage_request_form_lta.get('intellectual_property_owner_lta')?.invalid
    }

}
