import {Institution} from "./Institution";


export class OrcidUser {

    private _name: string | null
    private _surname: string | null
    private _email: string | null
    private _password: string | null
    private _institution: Institution | null


    constructor(name: string | null, surname: string | null, email: string | null, password: string | null, institution: Institution | null) {
        this._name = name;
        this._surname = surname;
        this._email = email;
        this._password = password;
        this._institution = institution;
    }


    get name(): string | null {
        return this._name;
    }

    set name(value: string | null) {
        this._name = value;
    }

    get surname(): string | null {
        return this._surname;
    }

    set surname(value: string | null) {
        this._surname = value;
    }

    get email(): string | null {
        return this._email;
    }

    set email(value: string | null) {
        this._email = value;
    }

    get password(): string | null {
        return this._password;
    }

    set password(value: string | null) {
        this._password = value;
    }

    get institution(): Institution | null {
        return this._institution;
    }

    set institution(value: Institution | null) {
        this._institution = value;
    }
}
