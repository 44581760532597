import {Role} from "./UserRole";
import {Institution} from "./Institution";


export enum Status {
    PENDING='PENDING',
    APPROVED='APPROVED',
    REJECTED='REJECTED',
    CONFIRMED='CONFIRMED',
    DECLINED='DECLINED'
}

export enum Deleted{
    ACTIVE ='ACTIVE',
    PENDING ='PENDING',
    DELETED ='DELETED',
    DECLINED ='DECLINED'

}


export class User {
    username(username: any) {
        throw new Error('Method not implemented.');
    }

    id!: number;
    email!: string;
    createdAt!: Date;
    institution!:Institution;
    status!: Status;
    firstName!: string;
    lastName!: string;
    applicationStatus?: string;
    motivation?: string;
    orcidRegistered?: boolean;
    roles!: Role[];
    deleted?:Deleted;
    uuid!:String;
    safireRegistered: any;

}
