import {Component, OnInit} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {OrcidUser} from "../model/OrcidUser";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {InstitutionService} from "../services/institution/institution.service";
import {Status, User} from "../model/User";
import {Institution} from "../model/Institution";
import {AuthenticationService} from "../services/auth/authentication.service";
import {OrcidService} from "../services/orcid/orcid.service";
import {noOnlySpacesValidator} from "../register/register.component";


declare var  $:any
@Component({
  selector: 'app-orcid',
  templateUrl: './orcid.component.html',
  styleUrls: ['./orcid.component.css']
})

export class OrcidComponent implements  OnInit{

    isMetadataLoading: boolean = false
    code: string | null = null;
    orcidUser: OrcidUser =  new OrcidUser(null, null, null, null, null)
    initialLoad: boolean = false
    logInLoading: boolean = false
    currentUser: User | undefined
    formCheck: boolean = false
    isOverrideLoading: boolean = false
    institutionList: Institution[] = []
    registerFormLoading: boolean = false
    registrationSuccessful: boolean = false

    isTermConditionCheckedError: boolean = false;
    isStoragePrivacyCheckedError: boolean = false;
    isStoragePolicyCheckedError: boolean = false;
    isRecaptureCheckedError: boolean = false;
    private isRecapture: boolean = false;

    private termsAndConditionsAccepted: boolean = false
    private storagePrivacyAccepted: boolean = false
    private storagePolicyAccepted: boolean = false
    charCount: number = 0;
    countdown: Number = 5
    leadingAndTrailingSpaces: boolean = false

    constructor(private router: Router,
                private formBuilder: FormBuilder,
                private activatedRoute:ActivatedRoute,
                private institutionService:InstitutionService,
                private orcidService:OrcidService,
                private authenticationService:AuthenticationService
    ) {}

    onInput(event: Event): void {
        const target = event.target as HTMLTextAreaElement;
        this.charCount = target.value.length;
      }
    registerForm = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        name: [null,[ Validators.required]],
        surname: [null,[ Validators.required]],
        motivation: [null,[ Validators.required, Validators.minLength(10), Validators.maxLength(300), noOnlySpacesValidator()]],
        institution: [null,[ Validators.required]],
    });

    terms_and_conditions_from = this.formBuilder.group({
        // motivation: ['', [Validators.required, Validators.max(200)]]
    });


    ngOnInit(): void {
        this.code = this.activatedRoute.snapshot.paramMap.get('code')

        //load institutions list
        lastValueFrom(this.institutionService.read_all()).then((data) => {
            this.institutionList = data
        }).catch(error => {
        }).finally(() => {
            setTimeout(()=> {
                $('#safireInstitution').dropdown({direction: 'upward'});
            }, 1000);

        })

        this.getOrcidMetadata()
    }

    getOrcidMetadata() {

        this.isMetadataLoading = true

        lastValueFrom(this.orcidService.getOrcidMetadata(this.code)).then((orcidUser) => {

            try {
                // @ts-ignore
                let name = orcidUser['person']['name']['given-names']['value']
                // @ts-ignore
                let surname = orcidUser['person']['name']['family-name']['value']
                // @ts-ignore
                let email = orcidUser['person']['emails']['email'][0]['email']
                // @ts-ignore
                let password = orcidUser['orcid-identifier']['path']

                this.orcidUser = new OrcidUser(name, surname, email, password, null)

                //set value to form
                this.registerForm.controls.name.setValue(name)
                this.registerForm.controls.surname.setValue(surname)
                this.registerForm.controls.email.setValue(email)

                // @ts-ignore
                this.checkUser(this.orcidUser.email)

            } catch (e) {
                $('#orcid_missing_private_data_modal').modal('setting', 'closable', false).modal('show');
            }

        }).finally(() => {
            this.isMetadataLoading = false
        })
    }

    checkUser(email:string) {
        // @ts-ignore
        lastValueFrom(this.orcidService.getUser(email)).then((users:User[]) => {
            if(users.length != 0){
                this.currentUser = users[0]
                //log in
                if(this.currentUser.orcidRegistered){
                    this.logInLoading = true
                    this.logInFormSubmit()
                }
            }else{
                //register
                this.initialLoad = false
            }
        }).catch(error => {

        }).finally(() => {
            this.initialLoad = false

            if(this.currentUser?.status != 'APPROVED'){
                localStorage.clear()
            }
        })
    }

    overrideUserWithOrcid() {
        this.isOverrideLoading = true
        lastValueFrom(this.orcidService.overrideUserWithOrcid(this.orcidUser.email, this.orcidUser.password)).then((user:User)=>{
            this.router.navigate(["home"])
           this.pageRouter();
        }).finally(()=>{
            this.isOverrideLoading = false
        })
    }

    registerFormSubmit() {

        if (!this.isRecapture) {
            this.isRecaptureCheckedError = true;
        }
        if (!this.storagePolicyAccepted) {
            this.isStoragePolicyCheckedError = true;
        }
        if (!this.storagePrivacyAccepted) {
            this.isStoragePrivacyCheckedError = true;
        }
        if (!this.termsAndConditionsAccepted) {
            this.isTermConditionCheckedError = true;
        }

        if(this.registerForm.valid && this.termsAndConditionsAccepted && this.storagePrivacyAccepted && this.storagePolicyAccepted && this.isRecapture) {

            this.registerFormLoading = true

            lastValueFrom(this.orcidService.registerWithOrcid({
                'name':this.registerForm.controls.name.getRawValue(),
                'surname': this.registerForm.controls.surname.getRawValue(),
                'email': this.registerForm.controls.email.getRawValue(),
                'motivation': this.registerForm.controls.motivation.getRawValue(),
                'password': this.orcidUser.password,
                'institutionId': this.registerForm.controls.institution.getRawValue()
            })).then((user:User)=>{
                this.registrationSuccessful = true
                setInterval(()=> {
                    // @ts-ignore
                    this.countdown = this.countdown - 1;

                    if (this.countdown == 0) {
                        this.router.navigate([""])
                    }
                }, 1000);
            }).finally(()=>{
                this.isOverrideLoading = false
                this.registerFormLoading = false

                $('#termsAndConditionOrcidModal').modal('hide');
            })
        }
    }

    logInFormSubmit() {
        lastValueFrom(this.authenticationService.logIn({
            "username": this.orcidUser.email,
            "password": this.orcidUser.password
        })).then(data=>{
            // @ts-ignore
            localStorage.setItem('access_token',data['access_token']);
            this.pageRouter()
        })
    }

    resolved(captchaResponse: string) {
        this.isRecapture = true;
        this.isRecaptureCheckedError = false;
    }


    pageRouter() {

        lastValueFrom(this.authenticationService.getUserProfile()).then((user: User) => {

            this.currentUser = user

            if (this.currentUser.status == Status.APPROVED && this.currentUser.roles[0].name == "ROLE_USER") {
                this.router.navigate(['home']).then();
            }

            if (this.currentUser.status == Status.APPROVED && this.currentUser.roles[0].name == "ROLE_ADMIN") {
                this.router.navigate(['admin/home']).then();
            }



        }).finally(()=>{
            this.logInLoading = false
        })
    }

    protected readonly Status = Status;

    goToHome() {
        $('#orcid_missing_private_data_modal').modal('hide');
        $('#termsAndConditionOrcidModal').modal('hide');
        this.router.navigate([""])
    }

    openTermsAndCondition() {
        this.formCheck = true
        if (this.registerForm.valid && !this.leadingAndTrailingSpaces) {
            $('#termsAndConditionOrcidModal').modal('setting', 'closable', false).modal('show')

            $('#terms_and_condition .checkbox ').checkbox({
                onChecked: () => {
                    this.termsAndConditionsAccepted = true;
                    this.isTermConditionCheckedError = false;
                },
                onUnchecked: () => {
                    this.termsAndConditionsAccepted = false;
                    this.isTermConditionCheckedError = true;
                }
            });
            $('#storage_privacy .checkbox ').checkbox({
                onChecked: () => {
                    this.storagePrivacyAccepted = true;
                    this.isStoragePrivacyCheckedError = false;
                },
                onUnchecked: () => {
                    this.storagePrivacyAccepted = false;
                    this.isStoragePrivacyCheckedError = true;
                }
            });
            $('#storage_policy .checkbox ').checkbox({

                onChecked: () => {
                    this.storagePolicyAccepted = true
                    this.isStoragePolicyCheckedError = false;
                },
                onUnchecked: () => {
                    this.storagePolicyAccepted = false;
                    this.isStoragePolicyCheckedError = true;
                }
            });
        }
    }

    trimSpaces(event: Event){
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;

        if (inputValue.startsWith(" ") || inputValue.endsWith(" ")){
            this.leadingAndTrailingSpaces = true
        }else {
            this.leadingAndTrailingSpaces = false
        }
    }
}
