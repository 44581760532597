import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Administrator } from 'src/app/model/Administrator';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/User';
import { AdministrationRequest } from 'src/app/model/AdministrationRequest ';
import { FailedEmails } from 'src/app/model/FailedEmails';
;

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {

  constructor(private httpClient : HttpClient) {

   }
   createAdministratorUser(administrationRequest: AdministrationRequest): Observable<any> {
    return this.httpClient.post(environment.base+"administration/save",administrationRequest);
  }
   getAdministratorUsers(): Observable<Administrator[]> {
    return this.httpClient.get<Administrator[]>(environment.base+"administration/list");
  }
  getAdministratorUserById(id: number): Observable<Administrator>{
    return this.httpClient.get<Administrator>(environment.base+"administration/user/get/"+id);
  }

  updateAdministratorUser(id:number, administrator:Administrator): Observable<Object>{
    return this.httpClient.put(environment.base+"administration/update/"+id, administrator);
  }
  deleteAdministratorUser(id:number): Observable<Object>{
    return this.httpClient.delete(environment.base+"administration/delete/"+id);
  }
  getAllUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(environment.base + 'admin/user/users');
}
addEmailList(): Observable<FailedEmails[]>{
  return this.httpClient.get<FailedEmails[]>(environment.base+ "auth/getfailedemails")
}
sendEmail(id: number): Observable<FailedEmails> {
  return this.httpClient.post<FailedEmails>(environment.base + "auth/hardsendfailedemails/"+id,{});
}
}
