import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/auth/authentication.service';
import { AppService } from '../services/app/app.service';
import { InstitutionService } from '../services/institution/institution.service';
import { RejectionService } from '../services/rejection/rejection.service';
import { StorageRequestService } from '../services/storage-request/storage-request.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageRequest } from '../model/Storagerequest';
import { User } from '../model/User';
import {lastValueFrom} from "rxjs";



@Component({
  selector: 'app-storage-application-status',
  templateUrl: './storage-application-status.component.html',
  styleUrls: ['./storage-application-status.component.css']
})
export class StorageApplicationStatusComponent implements OnInit {

    // @ts-ignore
    current_user: User | null = null;
     // @ts-ignore
    current_storage_user: StorageRequest | undefined = undefined
    // @ts-ignore
    current_req: StorageRequest[] = []

    step:boolean = false;

    new_rejected_requests: any;


    constructor(
        private authenticationService: AuthenticationService,
        private appService: AppService,
        private institutionService: InstitutionService,
        private rejectionService:RejectionService,
        private storageService:StorageRequestService,
        private formBuilder: FormBuilder,
        public router:Router) {
    }
  ngOnInit(): void {

      lastValueFrom(this.authenticationService.getUserProfile()).then((user: User) => {
      this.current_user = user

       lastValueFrom(this.storageService.get_requests_by_username(this.current_user?.email)).then((req: StorageRequest[]) => {

      this.current_req = req
      if(req != null){
        this.step = true
      }

      }).catch(error=>{
      })

  });

}
}
