<app-top-navigation></app-top-navigation>
<div class="ui container" style="margin-top: 100px">
    <h4 class="text-center">Update Administrator User</h4>
    <form class="ui form" (ngSubmit)="updateHelpFrom.form.valid && onSubmit()" #updateHelpFrom ="ngForm">
        <div class="two fields">
            <div class="field">
              <input type="text" value="{{updateGetHelpRequest.requestName}} {{updateGetHelpRequest.requestSurname}}" disabled>
            </div>
            <div class="field">
              <input type="text" value="{{updateGetHelpRequest.requestEmail}}" disabled>
            </div>
          </div>
        <div class="ui form">
            <div class="field">
              <label>Issue</label>
              <textarea rows="2" disabled>{{updateGetHelpRequest.issue}}</textarea>
            </div>
            <div class="field">
                <label>Reply</label>
                <textarea maxlength="3500" [(ngModel)]="updateGetHelpRequest.reply" name="reply" placeholder="Please Reply to {{updateGetHelpRequest.requestName}} {{updateGetHelpRequest.requestSurname}} " #reply="ngModel" maxlength="5000" required></textarea>
                 <label *ngIf="reply.value?.length">
                  {{reply.value.length}} Characters (Max Characters 5000)
                  </label>
                  <label *ngIf="(updateHelpFrom.submitted) && reply.errors?.['required']" style="color: red">
                  Please enter reply message to user as is required
                 </label>
              </div>
          </div><br/>
     <button class="ui orange button" type="submit">Reply</button>
     <button (click)="backToList()" style="float: right" button class="ui button compact">Cancel</button>
  </form>
</div>