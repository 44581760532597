import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthenticationService} from "../../services/auth/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authenticationService:AuthenticationService, private router:Router) {}

  canActivate():boolean {

    if(this.authenticationService.logged_in()){
      return true
    }else {
      this.router.navigate(['']).then()
      return false
    }
  }

}
